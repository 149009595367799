import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Typography,
  VariantProp,
} from '@mui/joy';

import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import i18next, { TFunction } from 'i18next';
import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import FormElement from '../../components-v2/form/form-element';
import { FormItem } from '../../types/config';
import Log from '../../util/log';
import AdditionalForm from './additional-form';
import { TokenValidationErrorKey } from '../../components-v2/form/format-validate';
import styles from './view.module.scss';
import PoweredByProveIcon from '../../resources/images/powered_by_prove.svg';

export interface PII {
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  dob: Dayjs | null;
  emailAddress: string | null;
}

export type IdentityFormData = PII & { phoneValidationError?: TokenValidationErrorKey | null };

export interface ViewProps extends PII {
  page: 'login' | 'claim';
  loading?: boolean;
  prefilled: boolean;
  piiReadOnly: boolean;
  submitDisabled?: boolean;
  formItems: FormItem[];
  surfaceExit: boolean;
  onChangePii: (data: IdentityFormData | null) => void;
  onFormItemChange: (item: FormItem, value: any) => void;
  onSubmit?: () => void;
  onBack: (reason: 'error') => void;
  t: TFunction;
}

interface FormElements extends HTMLFormControlsCollection {
  phoneNumber: HTMLInputElement;
  firstName: HTMLInputElement;
  lastName: HTMLInputElement;
  dob: HTMLInputElement;
  email: HTMLInputElement;
}
interface IdentityFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

function IdentityForm(props: ViewProps): JSX.Element {
  const {
    page,
    firstName,
    lastName,
    phoneNumber,
    dob,
    emailAddress,
    prefilled,
    piiReadOnly,
    loading,
    submitDisabled,
    formItems,
    surfaceExit,
    onChangePii,
    onFormItemChange,
    onSubmit,
    onBack,
    t,
  } = props;

  const handleSubmit = (event: React.FormEvent<IdentityFormElement>): void => {
    event.preventDefault();
    onSubmit?.();
  };

  // Form changes

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    Log.info('form: firstName change', value);
    onChangePii?.({
      firstName: value,
      lastName,
      phoneNumber,
      emailAddress,
      dob,
    });
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    Log.info('form: lastName change', value);
    onChangePii?.({
      firstName,
      lastName: value,
      phoneNumber,
      emailAddress,
      dob,
    });
  };

  const handleDobChange = (inputDate: Dayjs | null): void => {
    Log.info('form: handleDobChange', inputDate);
    if (!inputDate) {
      return;
    }
    onChangePii?.({
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      dob: inputDate,
    });
  };

  const firstNameTextField = useRef<HTMLInputElement>(null);

  const headerText = prefilled
    ? t('claim.prove.form.prefilled.title')
    : t('claim.prove.form.unfilled.title');

  const textFieldVariant: VariantProp = piiReadOnly ? 'soft' : 'plain';

  function textForPath(path: string): string | undefined {
    if (i18next.exists(path)) {
      return t(path) ?? undefined;
    }
    return undefined;
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ height: '100%' }}>
      <CssBaseline />
      <Box
        sx={{
          pt: 4,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        {/* Visa Figma does not include the unfilled reason */}
        {/* {!prefilled && (
          <Typography component="h6" level="body-md" mb={2}>
            {textForPath(`${page}.prove.form.unfilled.reason`)}
          </Typography>
        )} */}
        <Stack spacing={1.5} mb={4} sx={{ width: '100%' }}>
          <Typography component="h1" level="h3" textAlign="center">
            {headerText}
          </Typography>
          <Typography component="h2" level="body-sm" textAlign="center">
            {t(`${page}.prove.form.unfilled.sub_title`)}
          </Typography>
        </Stack>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ pt: 1, paddingBottom: '40px' }}
        >
          <Grid container spacing={1}>
            <Grid xs={12} sm={6}>
              <FormElement label={textForPath(`${page}.landing.input_label_first_name`)} required>
                <Input
                  name="firstName"
                  placeholder={textForPath(`${page}.landing.input_placeholder_first_name`)}
                  size="lg"
                  variant={textFieldVariant}
                  required
                  fullWidth
                  autoComplete="given-name"
                  readOnly={piiReadOnly}
                  value={firstName ?? ''}
                  onChange={handleFirstNameChange}
                />
              </FormElement>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormElement label={textForPath(`${page}.landing.input_label_last_name`)} required>
                <Input
                  name="lastName"
                  size="lg"
                  placeholder={textForPath(`${page}.landing.input_placeholder_last_name`)}
                  variant={textFieldVariant}
                  required
                  fullWidth
                  autoComplete="family-name"
                  readOnly={piiReadOnly}
                  value={lastName ?? ''}
                  onChange={handleLastNameChange}
                  sx={{ flexGrow: 1 }}
                />
              </FormElement>
            </Grid>
            <Grid xs={12}>
              <FormControl>
                <FormLabel>{textForPath(`${page}.prove.form.phone_label_read_only`)}</FormLabel>
                <Input readOnly value={phoneNumber ?? ''} />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <FormControl sx={{ mt: '16px', mb: '8px' }}>
                <FormLabel>{t(`${page}.prove.form.dob_label`)}</FormLabel>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  dateFormats={{ monthAndYear: 'MMMM' }}
                >
                  <DesktopDatePicker
                    name="dob"
                    views={['month', 'day']}
                    format="MM / DD"
                    readOnly={piiReadOnly}
                    value={dob}
                    onChange={(newValue): void => handleDobChange(newValue)}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: piiReadOnly ? 'outlined' : 'standard',
                        focused: piiReadOnly ? false : undefined,
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            {prefilled && (
              <Grid xs={12}>
                <Button
                  size="sm"
                  variant="plain"
                  startDecorator={<Edit />}
                  onClick={(): void => {
                    onChangePii?.(null);
                    firstNameTextField.current?.focus();
                  }}
                  disabled={!piiReadOnly}
                >
                  {t(`${page}.prove.form.prefilled.edit_button_text`)}
                </Button>
              </Grid>
            )}

            {formItems.length > 0 && (
              <>
                <Grid xs={12} mt={2} mb={2}>
                  <Typography component="h2" level="body-sm">
                    {t(`${page}.prove.form.section_additional_info`)}
                  </Typography>
                </Grid>

                <AdditionalForm
                  formItems={formItems}
                  onItemChange={(item, value): void => {
                    onFormItemChange(item, value);
                  }}
                />
              </>
            )}

            <Grid xs={12}>
              <Button
                size="lg"
                type="submit"
                fullWidth
                sx={{ mt: 3, mb: 2 }}
                disabled={submitDisabled}
                loading={loading}
              >
                {textForPath(`${page}.prove.form.button_text`)}
              </Button>
            </Grid>

            {surfaceExit && (
              <Grid container justifyContent="center" xs={12}>
                <Grid>
                  <Button
                    variant="plain"
                    disabled={loading}
                    onClick={(): void => {
                      onBack('error');
                    }}
                  >
                    {textForPath(`${page}.prove.form.button_text_alt_action`)}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
        <div style={{ flexGrow: 1 }} />
        <div className={styles.proveLogoContainer}>
          <img className={clsx(`powered-by-prove-icon`)} alt="" src={PoweredByProveIcon} />
        </div>
      </Box>
    </Container>
  );
}

IdentityForm.defaultProps = {
  loading: false,
  submitDisabled: false,
  onSubmit: undefined,
};

export default withTranslation()(IdentityForm);
