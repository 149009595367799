import { ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle, className = '.select-container'): string[] {
  const styles = [];

  let rules = '';
  // legacy
  if (theme.typography && 'mobile' in theme.typography && theme.typography.mobile) {
    // mobile
    const placeholder = theme.typography.mobile.placeholder?.font_color;
    if (placeholder && placeholder?.length > 0) {
      rules += `color:${placeholder};`;
    }
    if (rules.length > 0) {
      styles.push(`${className} > .select-box > .select-box-placeholder.mobile {${rules}}`);
    }
  }

  if (theme.typography && 'desktop' in theme.typography && theme.typography.desktop) {
    // desktop
    const placeholder = theme.typography.desktop.placeholder?.font_color;
    if (placeholder && placeholder?.length > 0) {
      rules += `color:${placeholder};`;
    }
    if (rules.length > 0) {
      styles.push(`${className} > .select-box > .select-box-placeholder.desktop {${rules}}`);
    }
  }

  // landing page overrides
  const {
    background_color: backgroundColor,
    border_radius: borderRadius,
    border_color: borderColor,
    border_width: borderWidth,
    border_style: borderStyle,
  } = theme.input ?? {};

  rules = '';

  if (backgroundColor && backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (borderColor && borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
  }
  if (borderWidth && borderWidth?.length > 0) {
    rules += `border-width:${borderWidth};`;
  }
  if (borderStyle && borderStyle?.length > 0) {
    rules += `border-style:${borderStyle};`;
  }
  if (borderRadius) {
    rules += `border-radius:${borderRadius};`;
  }

  if (rules.length > 0) {
    styles.push(`${className} > .select-box {${rules}}`);
  }

  return styles;
}
