import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import i18next, { TFunction } from 'i18next';
import styles from './index.module.scss';
import Source from '../../components/source';
import Image from '../../components/image';

export interface IndexProps {
  t: TFunction;
  prefix: string;
}

function Index(props: PropsWithChildren<IndexProps>): JSX.Element {
  const { t, children } = props;
  let { prefix } = props;

  const backdropImage = i18next.exists(`${prefix}.backdrop.background_image.src`)
    ? t(`${prefix}.backdrop.background_image.src`)
    : '';

  prefix += '.landing';

  const primaryAnchoredImage = i18next.exists(`${prefix}.anchored.background_image.src`)
    ? t(`${prefix}.anchored.background_image.src`)
    : null;

  const fallbackAnchoredImage = i18next.exists(`${prefix}.background_image.src`)
    ? t(`${prefix}.background_image.src`)
    : null;

  const anchoredImage = primaryAnchoredImage || fallbackAnchoredImage || '';

  let anchoredIframe = i18next.exists(`${prefix}.anchored.background_iframe.src`)
    ? t(`${prefix}.anchored.background_iframe.src`)
    : null;

  if (!anchoredIframe) {
    anchoredIframe = i18next.exists(`claim.landing.background_iframe.src`)
      ? t(`claim.landing.background_iframe.src`)
      : null;
  }

  return (
    <div
      className={clsx(`anchor-page-backdrop`, styles.backdrop)}
      style={{
        backgroundImage: `url(${backdropImage})`,
      }}
    >
      <div className={clsx('anchor-page-container', styles.container)}>
        <div className={clsx(`anchor-page-left-container`, styles.leftContainer)}>
          <video
            className={clsx(
              `anchor-anchored-background-video-container`,
              styles.anchoredVideoContainer
            )}
            autoPlay
            muted
            loop
          >
            <Source
              type="video/mp4"
              className={clsx(`anchor-anchored-background-video`, styles.video)}
            />
          </video>
          <div
            className={clsx(
              `anchor-anchored-background-image-container`,
              styles.anchoredImageContainer
            )}
          >
            <Image
              alt=""
              src={anchoredImage}
              className={clsx(`anchor-anchored-background-image`, styles.image)}
            />
          </div>

          {anchoredIframe && (
            <div
              className={clsx(
                `anchor-anchored-background-iframe-container`,
                styles.anchoredIframeContainer
              )}
            >
              <iframe
                title="Campaign Information"
                src={anchoredIframe}
                className={clsx(`anchor-anchored-background-iframe`, styles.iframe)}
              />
            </div>
          )}
        </div>

        <div className={clsx('anchor-page-content-container', styles.contentContainer)}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Index;
