import clsx from 'clsx';
import React from 'react';
import styles from './index.module.scss';
import Label from '../label';
import CheckboxBase from '../checkbox-base';

export interface IndexProps {
  style?: React.CSSProperties;
  className?: string;
  checked: boolean;
  text: string;
  onCheckedToggle: (checked: boolean) => void;
  disabled?: boolean;
  helperText?: string;
}

export default function CheckBox(props: IndexProps): JSX.Element {
  const { style, className, checked, text, onCheckedToggle, disabled, helperText } = props;
  return (
    <div
      style={style}
      className={clsx(
        className,
        styles.outerContainer,
        'checkbox-container',
        disabled && 'disabled'
      )}
    >
      {helperText && (
        <Label
          className={clsx('checkbox-helper-text', styles.helperText)}
          text={helperText}
          variant="subtitle2"
          color="warning"
        />
      )}
      <CheckboxBase text={text} checked={checked} onCheckedToggle={onCheckedToggle} />
    </div>
  );
}

CheckBox.defaultProps = {
  style: undefined,
  className: undefined,
  disabled: false,
  helperText: undefined,
};
