import { ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle, className = '.input-container'): string[] {
  const styles: string[] = [];

  let rules = '';
  if (theme.typography && 'mobile' in theme.typography && theme.typography.mobile) {
    const placeholderColor = theme.typography.mobile.placeholder?.font_color;
    if (placeholderColor && placeholderColor?.length > 0) {
      rules += `color:${placeholderColor};`;
    }
    styles.push(
      `${className} > .input-box > .input-field::-webkit-input-placeholder, ${className} > .input-box > .input-field.mobile::placeholder {${rules}}`
    );
  }
  if (theme.typography && 'desktop' in theme.typography && theme.typography.desktop) {
    const placeholderColor = theme.typography.desktop.placeholder?.font_color;
    if (placeholderColor && placeholderColor?.length > 0) {
      rules += `color:${placeholderColor};`;
    }
    styles.push(
      `${className} > .input-box > .input-field::-webkit-input-placeholder, ${className} > .input-box > .input-field.desktop::placeholder {${rules}}`
    );
  }

  rules = '';

  // landing page overrides
  const {
    background_color: backgroundColor,
    border_radius: borderRadius,
    border_color: borderColor,
    border_width: borderWidth,
    border_style: borderStyle,
  } = theme.input ?? {};

  rules = '';

  if (backgroundColor && backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (borderColor && borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
  }
  if (borderWidth && borderWidth?.length > 0) {
    rules += `border-width:${borderWidth};`;
  }
  if (borderStyle && borderStyle?.length > 0) {
    rules += `border-style:${borderStyle};`;
  }
  if (borderRadius) {
    rules += `border-radius:${borderRadius};`;
  }

  if (rules.length > 0) {
    styles.push(`${className} > .input-box {${rules}}`);
  }

  return styles;
}
