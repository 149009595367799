import { Box, Button, Container, CssBaseline, Grid, Stack, Typography } from '@mui/joy';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import i18next, { TFunction } from 'i18next';
import React, { useRef } from 'react';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import FormElement from '../../components-v2/form/form-element';
import FormInputToken, { Token } from '../../components-v2/form/form-input-token';
import { TokenValidationErrorKey } from '../../components-v2/form/format-validate';
import styles from './view.module.scss';
import PoweredByProveIcon from '../../resources/images/powered_by_prove.svg';

interface ChallengeProps {
  /** If MobileAuth succeeded, then the number should be passed in and displayed as read-only, maybe stared, with last 4 digits shown */
  t: TFunction;
  page: 'login' | 'claim';
  phoneNumber: string;
  phoneReadOnly?: boolean;
  allowedRegions: string[];
  dob?: Dayjs | null;
  disableDobPicker: boolean;
  loading?: boolean;
  submitDisabled?: boolean;
  onChange: (
    phoneNumber?: string,
    validationError?: TokenValidationErrorKey | null,
    dob?: Dayjs | null
  ) => void;
  onSubmit?: () => void;
  onBack: (reason: 'error') => void;
}

interface FormElements extends HTMLFormControlsCollection {
  dob: HTMLInputElement;
  phoneNumber?: HTMLInputElement;
}
interface IdentityFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

/**
 * Challenge asks the user for two pieces of info: dob and phone number.
 * The phone number is passed in (from Mobile Auth), or must be collected in fallback case.
 */
function IdentityChallenge(props: ChallengeProps): JSX.Element {
  const {
    t,
    page,
    phoneNumber,
    phoneReadOnly,
    allowedRegions,
    dob,
    disableDobPicker,
    loading,
    submitDisabled,
    onChange,
    onSubmit,
    onBack,
  } = props;

  const stateRef = useRef('');

  const handleDobChange = (newValue: Dayjs | null): void => {
    onChange?.(undefined, undefined, newValue);
  };

  const handleTokenChange = (value: Token): void => {
    stateRef.current = value.formattedToken?.value ?? '';
    onChange?.(value.formattedToken?.value ?? '', value.errorKey ?? null, undefined);
  };

  const handleSubmit = async (event: React.FormEvent<IdentityFormElement>): Promise<void> => {
    event.preventDefault();
    onSubmit?.();
  };

  function textForPath(path: string): string | undefined {
    if (i18next.exists(path)) {
      return t(path) ?? undefined;
    }
    return undefined;
  }

  const tokenHelperText =
    stateRef.current.length > 0
      ? `E.164 ${stateRef.current}`
      : textForPath(`${page}.landing.input_helper_phone`);

  return (
    <Container component="main" sx={{ height: '100%' }}>
      <CssBaseline />
      <Box
        sx={{
          pt: 4,
          pb: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Stack spacing={2} mb={1}>
          <Typography component="h1" level="h3" textAlign="center">
            {textForPath(`${page}.prove.challenge.title`)}
          </Typography>
          <Typography component="h2" level="body-sm" textAlign="center">
            {textForPath(`${page}.prove.challenge.sub_title`)}
          </Typography>
        </Stack>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ width: '100%' }}>
          <Stack spacing={2} mt={3}>
            {page === 'claim' && (
              <FormElement
                sx={{ mt: '16px', mb: '8px' }}
                label={textForPath(`${page}.prove.challenge.dob_label`)}
                helper={textForPath(`${page}.prove.challenge.dob_helper`)}
                required
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  dateFormats={{ monthAndYear: 'MMMM' }}
                >
                  <DesktopDatePicker
                    name="dob"
                    label={null}
                    views={['month', 'day']}
                    format="MM / DD"
                    disabled={disableDobPicker}
                    value={dob}
                    onChange={(newValue): void => handleDobChange(newValue)}
                    readOnly={loading}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        variant: 'outlined',
                      },
                    }}
                  />
                </LocalizationProvider>
              </FormElement>
            )}

            {!phoneReadOnly && (
              <FormInputToken
                allowPhone
                allowEmail={false}
                size="lg"
                required
                label={textForPath(`${page}.prove.challenge.phone_label`)}
                helper={tokenHelperText}
                placeholder={textForPath(`${page}.prove.challenge.phone_placeholder`)}
                regions={allowedRegions}
                defaultValue={phoneNumber}
                inputProps={{
                  readOnly: phoneReadOnly,
                  fullWidth: true,
                  name: 'phoneNumber',
                  variant: 'plain',
                  type: 'text',
                  inputMode: 'tel',
                  autoComplete: 'tel',
                }}
                onChange={(token: Token): void => {
                  handleTokenChange(token);
                }}
              />
            )}
          </Stack>

          <Button
            type="submit"
            size="lg"
            sx={{ width: '100%', mt: 4 }}
            loading={loading}
            disabled={submitDisabled}
          >
            {textForPath(`${page}.prove.challenge.button_text`)}
          </Button>

          {!phoneReadOnly && (
            <Grid container justifyContent="center" sx={{ mt: 2 }}>
              <Grid>
                <Button
                  variant="plain"
                  disabled={loading}
                  onClick={(): void => {
                    onBack('error');
                  }}
                >
                  {textForPath(`${page}.prove.challenge.no_mobile`)}
                </Button>
              </Grid>
            </Grid>
          )}
        </Box>
        <div style={{ flexGrow: 1 }} />
        <div className={styles.proveLogoContainer}>
          <img className={clsx(`powered-by-prove-icon`)} alt="" src={PoweredByProveIcon} />
        </div>
        {/* <Typography sx={{ pt: '24px', pb: '24px' }} level="footer">
          {t('claim.landing.footer')}
        </Typography> */}
      </Box>
    </Container>
  );
}

IdentityChallenge.defaultProps = {
  dob: null,
  phoneReadOnly: false,
  loading: false,
  submitDisabled: false,
  onSubmit: undefined,
};

export default withTranslation()(IdentityChallenge);
