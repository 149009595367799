import React, { Component } from 'react';
import Presenter from './presenter';
import Model, { StateModel } from './model';
import View from './view';
import { Config, ConsentType, RawFormItem } from '../../../types/config';
import { AuthProvider } from '../../../types/common';
import Placeholder from './view-placeholder';

interface IndexProps {
  config: Config;
  language: string;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  isConsentPopupContinueEnabled: boolean;
  showProveFallback: boolean;
  onNext: (authProvider: AuthProvider) => void;
  onConsentChange: (type: ConsentType, consent: boolean) => void;
  onPreFormItemsChange: (items: [RawFormItem]) => void;
}

export default class ClaimMultiLanding extends Component<IndexProps> {
  presenter: Presenter;

  constructor(props: IndexProps) {
    super(props);
    const { config, showProveFallback, onNext, onConsentChange, onPreFormItemsChange } = props;
    this.presenter = new Presenter(
      new Model(this),
      config,
      showProveFallback,
      onNext,
      onConsentChange,
      onPreFormItemsChange
    );
  }

  componentDidMount(): void {
    this.presenter.onAttach();
  }

  componentWillUnmount(): void {
    this.presenter.onDetach();
  }

  render(): JSX.Element {
    const {
      config,
      language,
      acceptedPrimaryConsent,
      acceptedMarketingConsent,
      isConsentPopupContinueEnabled,
      onConsentChange,
    } = this.props;

    const languagePolicies =
      config.claim.consent.policies &&
      Object.keys(config.claim.consent.policies).length > 0 &&
      config.claim.consent.policies[language];

    if (languagePolicies) {
      return (
        <View
          {...(this.state as StateModel)}
          acceptedPrimaryConsent={acceptedPrimaryConsent}
          acceptedMarketingConsent={acceptedMarketingConsent}
          isConsentPopupContinueEnabled={isConsentPopupContinueEnabled}
          onConsentChange={onConsentChange}
          presenter={this.presenter}
          languagePolicies={languagePolicies}
        />
      );
    }
    return <Placeholder />;
  }
}
