/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import useMediaQuery from '@mui/material/useMediaQuery';
import styles from './index.module.scss';
import { ColorVariant, TypographyVariant } from '../../types/theme';

export interface IndexProps {
  className?: string;
  style?: any;
  text: string;
  onClick?: () => void;
  variant?: TypographyVariant;
  color?: ColorVariant;
}

export default function Label(props: IndexProps): JSX.Element {
  const { className, style, text, onClick, variant, color } = props;
  const pureText = DOMPurify.sanitize(text);
  const isMobile = useMediaQuery('(max-width: 856px)');
  const deviceClass = isMobile ? 'mobile' : 'desktop';
  return (
    <div
      className={clsx(styles.label, 'label', deviceClass, className, variant, color)}
      style={style}
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: pureText,
      }}
    />
  );
}

Label.defaultProps = {
  className: undefined,
  style: undefined,
  onClick: undefined,
  variant: 'body1',
  color: undefined,
};
