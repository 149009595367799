/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';
import styles from './index.module.scss';
import Label from '../label';
import { ColorVariant } from '../../types/theme';

export interface IndexProps {
  className?: string;
  style?: React.CSSProperties;
  label?: string | null;
  helperText?: string | null;
  leftText?: string | null;
  disableLeft?: boolean;
  onLeftClick?: () => void;
  value?: string;
  placeholder?: string | null;
  type?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  onChange?: (value: string) => void;
  focus?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export default function Input(props: IndexProps): JSX.Element {
  const {
    className,
    style,
    label,
    helperText,
    leftText,
    disableLeft,
    onLeftClick,
    value,
    placeholder,
    type,
    autoCorrect,
    autoCapitalize,
    onChange,
    focus,
    disabled,
    required,
  } = props;

  const [focused, setFocused] = useState(false);
  const isMobile = useMediaQuery('(max-width: 856px)');
  const deviceClass = isMobile ? 'mobile' : 'desktop';
  const labelColor: ColorVariant | undefined =
    helperText && helperText.length > 0 ? 'warning' : undefined;

  // Render button
  return (
    <div
      style={style}
      className={clsx(className, 'input-container', styles.container, disabled && 'disabled')}
    >
      {!helperText && label && (
        <div className={clsx('input-label-container', styles.labelContainer)}>
          <Label className={clsx('input-label', styles.label)} text={label} variant="subtitle2" />
          {required && (
            <Label
              className={clsx('input-label', 'required-label', styles.labelRequired)}
              variant="subtitle2"
              text="*"
              color="warning"
            />
          )}
        </div>
      )}
      {helperText && (
        <Label
          className={clsx('input-helper-text', styles.helperText)}
          text={helperText}
          variant="subtitle2"
          color={labelColor}
        />
      )}
      <div
        className={clsx(
          'input-box',
          deviceClass,
          focused && 'input-box-focused',
          styles.inputBox,
          focused && styles.focused
        )}
      >
        {leftText && (
          <Label
            className={clsx(
              'input-left-text',
              disableLeft && 'input-left-text-disabled',
              styles.leftText,
              disableLeft && styles.leftDisabled
            )}
            onClick={(): void => {
              if (!disabled && !disableLeft) {
                onLeftClick?.();
              }
            }}
            text={leftText}
          />
        )}
        <input
          className={clsx('input-field', deviceClass, styles.input, '')}
          value={value}
          placeholder={placeholder ?? ''}
          type={type}
          onChange={(event): void => {
            if (!disabled) {
              onChange?.(event?.target?.value);
            }
          }}
          onFocus={(): void => setFocused(true)}
          onBlur={(): void => setFocused(false)}
          autoCorrect={autoCorrect}
          autoCapitalize={autoCapitalize}
          autoFocus={focus}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

Input.defaultProps = {
  className: undefined,
  style: undefined,
  label: undefined,
  helperText: undefined,
  leftText: undefined,
  disableLeft: undefined,
  onLeftClick: undefined,
  value: undefined,
  placeholder: undefined,
  type: undefined,
  autoCorrect: undefined,
  autoCapitalize: undefined,
  onChange: undefined,
  focus: undefined,
  disabled: undefined,
  required: undefined,
};
