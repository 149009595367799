/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './index.module.scss';
import Label from '../label';

export interface IndexProps {
  className?: string;
  checked: boolean;
  text: string;
  onCheckedToggle: (checked: boolean) => void;
  disabled?: boolean;
}

function CheckboxBase(props: IndexProps): JSX.Element {
  const { checked, disabled, text, className, onCheckedToggle } = props;
  const [checkColor, setCheckColor] = useState('#fff');

  return (
    <div className={clsx(className, styles.container, 'checkbox-inner-container')}>
      <span
        className={clsx(
          'checkbox-field',
          styles.checkbox,
          checked && styles.checked,
          checked && 'checked'
        )}
        onClick={(): void => {
          if (!disabled) {
            onCheckedToggle?.(!checked);
          }
        }}
      >
        {checked && (
          <svg
            ref={(element): void => {
              if (element != null) {
                const checkStyles = getComputedStyle(element);
                setCheckColor(checkStyles?.color ?? 'fff');
              }
            }}
            className={clsx('checkbox-tick', styles.check)}
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill={checkColor}
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
          </svg>
        )}
      </span>

      <Label className={clsx('checkbox-text', styles.text)} text={text} variant="body2" />
    </div>
  );
}

CheckboxBase.defaultProps = {
  className: undefined,
  disabled: false,
};

export default CheckboxBase;
