import React from 'react';
import i18next from 'i18next';
import { useTranslation, withTranslation } from 'react-i18next';
import ViewBase from '../../../views/view-base';

function View(): JSX.Element {
  const { t } = useTranslation();

  const backgroundImageImageSrc = i18next.exists('claim.landing.background_image.src')
    ? t('claim.landing.background_image.src')
    : null;

  return (
    <ViewBase
      headerText={t('claim.landing.text')}
      prefix="claim-placeholder"
      backgroundImageSrc={backgroundImageImageSrc}
      footerText={t('claim.landing.footer')}
    />
  );
}

export default withTranslation()(View);
