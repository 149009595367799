import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/joy';
import DOMPurify from 'dompurify';

export interface IndexProps extends Pick<CheckboxProps, 'checked' | 'size'> {
  label: string;
  onToggle: (checked: boolean) => void;
  ariaLabel?: string;
}

export default function BaseCheckbox(props: IndexProps): JSX.Element {
  const { ariaLabel, label, checked, size, onToggle } = props;
  const pureText = DOMPurify.sanitize(label);

  return (
    <Checkbox
      size={size}
      checked={checked}
      slotProps={{
        input: {
          'aria-label': ariaLabel,
        },
      }}
      sx={{ typography: 'body-sm' }}
      tabIndex={0}
      onChange={(): void => {
        onToggle?.(!checked);
      }}
      onKeyDown={(event): void => {
        if (event.key === 'Enter') {
          onToggle?.(!checked);
        }
      }}
      label={
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: pureText,
          }}
        />
      }
    />
  );
}

BaseCheckbox.defaultProps = {
  ariaLabel: undefined,
};
