import { ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  const styles: string[] = [];

  // landing page overrides
  const {
    background_color: backgroundColor,
    border_radius: borderRadius,
    border_color: borderColor,
    border_width: borderWidth,
    border_style: borderStyle,
  } = theme.input ?? {};

  // set focus style
  const { secondary } = theme.palette ?? {};
  let rules = '';
  if (secondary && secondary?.length > 0) {
    rules += `border-color:${secondary};`;
  } else {
    rules += `border-color:${borderColor};`;
  }
  styles.push(`.otp-input-inner-input:focus {${rules}}`);
  rules = '';

  // set default style
  if (backgroundColor && backgroundColor?.length > 0) {
    rules += `background-color:${backgroundColor};`;
  }
  if (borderColor && borderColor?.length > 0) {
    rules += `border-color:${borderColor};`;
  }
  if (borderWidth && borderWidth?.length > 0) {
    rules += `border-width:${borderWidth};`;
  }
  if (borderStyle && borderStyle?.length > 0) {
    rules += `border-style:${borderStyle};`;
  }
  if (borderRadius) {
    rules += `border-radius:${borderRadius};`;
  }

  if (rules.length > 0) {
    styles.push(`.otp-input-inner-input {${rules}}`);
  }

  styles.push(`
    .otp-input-inner-input.mobile {
      color: ${theme.typography?.mobile?.body1?.font_color};
      font-family: ${theme.typography?.mobile?.body1?.font_family};
    }
  `);

  styles.push(`
    .otp-input-inner-input.desktop {
      color: ${theme.typography?.desktop?.body1?.font_color};
      font-family: ${theme.typography?.desktop?.body1?.font_family};
    }
  `);

  return styles;
}
