import { FormControl, FormControlProps, FormHelperText, FormLabel } from '@mui/joy';
import React from 'react';

export interface FormElementProps extends FormControlProps {
  label?: string;
  helper?: string;
}

function FormElement(props: FormElementProps): JSX.Element {
  const { id, label, helper, children, ...formControlProps } = props;

  return (
    <FormControl sx={{ width: '100%' }} {...formControlProps}>
      <FormLabel>{label}</FormLabel>
      {children}
      <FormHelperText>{helper}</FormHelperText>
    </FormControl>
  );
}

FormElement.defaultProps = {
  label: undefined,
  helper: undefined,
};

export default FormElement;
