import React, { ReactNode } from 'react';
import clsx from 'clsx';
import OtpInput from 'react-otp-input';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import styles from './index.module.scss';
import Label from '../label';

interface ViewProps {
  className?: string;
  style?: React.CSSProperties;
  label?: string | null;
  helperText?: string | null;
  disabled?: boolean;
  required?: boolean;
  numInputs?: number;
  value: string;
  onChange?: (otp: string) => void;
}

export default function Input(props: ViewProps): JSX.Element {
  const { className, style, label, helperText, disabled, required, numInputs, value, onChange } =
    props;
  const isMobile = useMediaQuery('(max-width: 856px)');
  const deviceClass = isMobile ? 'mobile' : 'desktop';

  return (
    <div
      style={style}
      className={clsx(styles.container, 'otp-input-container', disabled && 'disabled', className)}
    >
      {label && (
        <div className={clsx('otp-input-label-container', styles.labelContainer)}>
          {!required && (
            <Label
              className={clsx('otp-input-label', styles.label)}
              text={label}
              variant="subtitle2"
            />
          )}
          {required && (
            <Label
              className={clsx('otp-input-helper-text', styles.helperText)}
              text={helperText ?? ''}
              variant="subtitle2"
              color="warning"
            />
          )}
        </div>
      )}
      <OtpInput
        value={value}
        numInputs={numInputs}
        onChange={(otp: string): void => onChange?.(otp)}
        inputType="number"
        shouldAutoFocus
        renderInput={(inputProps): ReactNode => <input {...inputProps} />}
        containerStyle={styles.inputContainerStyle}
        inputStyle={clsx(styles.inputStyle, 'otp-input-inner-input', deviceClass)}
      />
    </div>
  );
}

Input.defaultProps = {
  className: undefined,
  style: undefined,
  label: '',
  helperText: '',
  disabled: false,
  required: false,
  numInputs: 6,
  onChange: undefined,
};
