/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from '@mui/material';
import Measure, { BoundingRect } from 'react-measure';
import styles from './index.module.scss';
import Label from '../label';

export interface SelectProps {
  style?: React.CSSProperties;
  className?: string;
  placeholder?: string | null;
  helperText?: string | null;
  disabled?: boolean;
  label?: string | null;
  onSelect: (value: string) => void;
  selected: string | null;
  items: { value: string; name: string }[];
  required?: boolean;
}

export default function Select(props: SelectProps): JSX.Element {
  const {
    style,
    className,
    placeholder,
    helperText,
    disabled,
    label,
    onSelect,
    selected,
    items,
    required,
  } = props;
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState<BoundingRect | undefined>(undefined);
  let selectedName = items.find((item) => item.value === selected)?.name;
  let usePlaceholder = false;
  if (!selectedName && placeholder) {
    selectedName = placeholder;
    usePlaceholder = true;
  }
  const isMobile = useMediaQuery('(max-width: 856px)');

  return (
    <div
      style={style}
      className={clsx(
        className,
        'select-container',
        styles.container,
        disabled && 'disabled',
        open && styles.open,
        open && 'open'
      )}
    >
      {!helperText && label && (
        <div className={clsx('select-label-container', styles.labelContainer)}>
          <Label className={clsx('select-label', styles.label)} text={label} variant="subtitle2" />
          {required && (
            <Label
              className={clsx('required-label', styles.label, styles.labelRequired)}
              text="*"
              variant="subtitle2"
              color="warning"
            />
          )}
        </div>
      )}
      {helperText && (
        <Label
          className={clsx('select-helper-text', styles.helperText)}
          text={helperText}
          variant="subtitle2"
          color="warning"
        />
      )}
      {!isMobile && (
        <>
          <div
            className={clsx('select-box', styles.box)}
            onClick={(): void => {
              if (!disabled) {
                setOpen(!open);
              }
            }}
          >
            <div
              className={clsx(
                usePlaceholder && 'select-box-placeholder',
                !usePlaceholder && 'select-box-selected',
                styles.selected,
                'desktop'
              )}
            >
              {selectedName}
            </div>
            {open && <div className={clsx('select-box-icon-collapse', styles.collapse)} />}
            {!open && <div className={clsx('select-box-icon-expand', styles.expand)} />}
          </div>
          {open && (
            <div className={clsx('select-dropdown-container', styles.dropdownContainer)}>
              <div className={clsx('select-dropdown', styles.dropdown)}>
                {items.map((item) => {
                  return (
                    <div
                      className={clsx(
                        'select-item-container',
                        styles.box,
                        item.value === selected && 'selected'
                      )}
                      key={item.value}
                      onClick={(): void => {
                        setOpen(false);
                        onSelect?.(item.value);
                      }}
                    >
                      <div className={clsx('select-item', styles.selected)}>{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}

      {isMobile && (
        <Measure
          bounds
          onResize={({ bounds }): void => {
            setSize(bounds);
          }}
        >
          {({ measureRef }): JSX.Element => (
            <div ref={measureRef} className={clsx('select-box', styles.box)}>
              <div
                className={clsx(
                  usePlaceholder && 'select-box-placeholder',
                  !usePlaceholder && 'select-box-selected',
                  styles.selected,
                  'mobile'
                )}
              >
                {selectedName}
              </div>
              {open && <div className={clsx('select-box-icon-collapse', styles.collapse)} />}
              {!open && <div className={clsx('select-box-icon-expand', styles.expand)} />}
              <select
                style={{ width: size?.width, height: size?.height }}
                className={clsx(styles.select)}
                value={selected || ''}
                onChange={(event): void => {
                  onSelect?.(event.target.value);
                }}
              >
                <option key="placeholder" value="">
                  {placeholder}
                </option>
                {items.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
        </Measure>
      )}
    </div>
  );
}

Select.defaultProps = {
  style: undefined,
  className: undefined,
  placeholder: undefined,
  label: undefined,
  required: false,
  disabled: false,
  helperText: undefined,
};
