/* eslint-disable jsx-a11y/control-has-associated-label */
import clsx from 'clsx';
import i18next, { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Button from '../../../components/button';
import CheckBox from '../../../components/checkbox';
import Input from '../../../components/input';
import Label from '../../../components/label';
import Select from '../../../components/select';
import { StateModel } from './model';
import Presenter from './presenter';
import styles from './view.module.scss';
import BackButton from '../../../components/button-back';
import {
  ConsentType,
  InputAdditionalItem,
  FormItem,
  LanguagePolicies,
} from '../../../types/config';
import { AuthProvider } from '../../../types/common';
import FormItemsView from '../form-items-view';

interface ViewProps extends StateModel {
  t: TFunction;
  presenter: Presenter;
  authProvider: AuthProvider;
  inputToken: string;
  onTokenChange: (token: string) => void;
  avatarURL: string | null;
  socialName: string | null;
  inputName: string;
  onNameChange: (name: string) => void;
  inputCustom: InputAdditionalItem[] | null;
  onCustomChange: (name: string, custom: InputAdditionalItem) => void;
  inputAdditional: string | null;
  onAdditionalChange: (value: string) => void;
  formItems: FormItem[];
  onFormItemChange: (item: FormItem, value: any) => void;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  onConsentChange: (type: ConsentType, consent: boolean) => void;
  isAuthComplete: boolean;
  languagePolicies: LanguagePolicies;
}

function View(props: ViewProps): JSX.Element {
  const {
    t,
    errorToken,
    allowEmail,
    allowPhone,
    errorName,
    captureName,
    nameType,
    countryCode,
    showCountryCode,
    presenter,
    disableCountryPicker,
    loading,
    errorPrimaryConsent,
    showMarketingConsent,
    showPrimaryConsent,
    errorAdditional,
    advertiserName,
    marketingConsentTerms,
    authProvider,
    inputToken,
    onTokenChange,
    inputName,
    onNameChange,
    avatarURL,
    inputCustom,
    onCustomChange,
    captureAdditional,
    inputAdditional,
    onAdditionalChange,
    formItems,
    onFormItemChange,
    acceptedPrimaryConsent,
    acceptedMarketingConsent,
    onConsentChange,
    isAuthComplete,
    socialName,
    languagePolicies,
  } = props;

  let label = null;
  let placeholder = null;
  let type = 'text';
  if (allowEmail && allowPhone) {
    label = i18next.exists('claim.landing.input_label_email_phone')
      ? t('claim.landing.input_label_email_phone')
      : null;
    placeholder = i18next.exists('claim.landing.input_placeholder_email_phone')
      ? t('claim.landing.input_placeholder_email_phone')
      : null;
  } else if (allowEmail) {
    label = i18next.exists('claim.landing.input_label_email')
      ? t('claim.landing.input_label_email')
      : null;
    placeholder = i18next.exists('claim.landing.input_placeholder_email')
      ? t('claim.landing.input_placeholder_email')
      : null;
    type = 'email';
  } else if (allowPhone) {
    label = i18next.exists('claim.landing.input_label_phone')
      ? t('claim.landing.input_label_phone')
      : null;
    placeholder = i18next.exists('claim.landing.input_placeholder_phone')
      ? t('claim.landing.input_placeholder_phone')
      : null;
    type = 'tel';
  }

  let nameLabel = 'claim.landing.input_label_full_name';
  let namePlaceholder = 'claim.landing.input_placeholder_full_name';

  if (nameType === 'first_name') {
    nameLabel = 'claim.landing.input_label_first_name';
    namePlaceholder = 'claim.landing.input_placeholder_first_name';
  } else if (nameType === 'last_name') {
    nameLabel = 'claim.landing.input_label_last_name';
    namePlaceholder = 'claim.landing.input_placeholder_last_name';
  }

  // Check for a client consent (override)
  const showClientConsent = i18next.exists('claim.landing.consent_client')
    ? t('claim.landing.consent_client')
    : null;

  let consentPrimaryText = '';

  // Primary could be: Platform Only, Platform + Client, or Custom Only
  // For Custom, locale key to overridden
  if (showPrimaryConsent) {
    // Add platform or custom
    consentPrimaryText = t('claim.landing.consent_primary_tokenized', {
      terms: languagePolicies.platform_terms,
      privacy: languagePolicies.platform_privacy,
      nft: languagePolicies.platform_nft,
    });
    // Add client text if needed (only for platform)
    if (showClientConsent) {
      consentPrimaryText += ` ${t('claim.landing.consent_client')}`;
    }
  }

  return (
    <div className={clsx('claim-landing-capture-container', styles.container)}>
      <div className={clsx('claim-landing-capture-inner-container', styles.innerContainer)}>
        <div className={(clsx(''), styles.headerContainer)}>
          <div className={styles.headerSpacer}>
            {!isAuthComplete && (
              <BackButton
                className="claim-back-button"
                onClick={(): void => presenter.onBackClick()}
                disabled={false}
              />
            )}
          </div>

          <Label
            className={clsx('claim-landing-capture-detail-text', styles.text)}
            text={t('claim.landing.detail_text')}
            variant="h5"
          />
          <div className={styles.headerSpacer} />
        </div>

        {authProvider !== 'token' && (
          <div className={styles.socialInfoContainer}>
            {avatarURL && avatarURL !== '' && (
              <img
                className={clsx('claim-landing-capture-avatar', styles.avatar)}
                alt="Avatar"
                src={avatarURL}
              />
            )}
            {socialName && socialName !== '' && (
              <Label
                className={(clsx('claim-landing-capture-welcome-label'), styles.welcomeNameLabel)}
                text={`Hi ${socialName}!`}
              />
            )}
          </div>
        )}

        {captureName && (
          <Input
            className={clsx(
              styles.input,
              'claim-landing-input',
              'claim-landing-input-name',
              errorName && 'error'
            )}
            value={inputName}
            label={i18next.exists(nameLabel) ? t(nameLabel) : null}
            placeholder={i18next.exists(namePlaceholder) ? t(namePlaceholder) : null}
            helperText={errorName && t(errorName)}
            onChange={(value: string): void => onNameChange(value)}
            type="text"
            disabled={loading}
            required
          />
        )}

        {authProvider === 'token' && (
          <Input
            className={clsx(
              styles.input,
              'claim-landing-input',
              'claim-landing-input-token',
              errorToken && 'error'
            )}
            value={inputToken}
            label={label}
            placeholder={placeholder}
            leftText={showCountryCode ? countryCode : null}
            helperText={errorToken && t(errorToken)}
            onChange={(value: string): void => {
              presenter.onTokenChange(value);
              onTokenChange(value);
            }}
            onLeftClick={(): void => presenter.onCountryCodeClick()}
            disableLeft={disableCountryPicker}
            type={type}
            disabled={loading}
            required
          />
        )}

        {captureAdditional && captureAdditional.length > 0 && (
          <Select
            className={clsx(
              styles.select,
              'claim-landing-select-additional',
              errorAdditional && 'error'
            )}
            label={
              i18next.exists('claim.landing.additional.label')
                ? t('claim.landing.additional.label')
                : ''
            }
            placeholder={
              i18next.exists('claim.landing.additional.placeholder')
                ? t('claim.landing.additional.placeholder')
                : ''
            }
            items={captureAdditional.map((item) => ({ value: item.value, name: t(item.name) }))}
            onSelect={(value: string): void => onAdditionalChange(value)}
            helperText={errorAdditional && t(errorAdditional)}
            selected={inputAdditional}
            disabled={loading}
            required
          />
        )}
        {inputCustom?.map((custom, index) => {
          return (
            <Input
              // eslint-disable-next-line react/no-array-index-key
              key={`custom-input-${index}`}
              className={clsx(
                styles.input,
                'claim-landing-input',
                `claim-landing-input-custom${index}`,
                custom.error && 'error'
              )}
              value={custom.value || ''}
              label={t(custom.name)}
              placeholder={custom.placeholder && t(custom.placeholder)}
              helperText={custom.error && t(custom.error)}
              onChange={(value: string): void => onCustomChange(value, custom)}
              type="text"
              disabled={loading}
              required={custom.required}
            />
          );
        })}
        {authProvider === 'token' && showPrimaryConsent && (
          <CheckBox
            className={clsx(
              styles.checkbox,
              'claim-landing-checkbox',
              'claim-landing-checkbox-primary',
              errorPrimaryConsent && 'error'
            )}
            text={consentPrimaryText}
            helperText={errorPrimaryConsent && t(errorPrimaryConsent)}
            disabled={loading}
            checked={acceptedPrimaryConsent}
            onCheckedToggle={(consent: boolean): void => onConsentChange('primary', consent)}
          />
        )}
        {authProvider === 'token' && showMarketingConsent && (
          <CheckBox
            className={clsx(
              styles.checkbox,
              'claim-landing-checkbox',
              'claim-landing-checkbox-marketing'
            )}
            text={t('claim.landing.consent_marketing', {
              advertiser: advertiserName,
              terms: marketingConsentTerms,
              interpolation: { escapeValue: false },
            })}
            disabled={loading}
            checked={acceptedMarketingConsent}
            onCheckedToggle={(consent: boolean): void => onConsentChange('marketing', consent)}
          />
        )}

        <FormItemsView formItems={formItems} onFormItemChange={onFormItemChange} />

        <div className={styles.spacer1} />
        <Button
          className={clsx('claim-landing-button', styles.button)}
          text={t('claim.landing.button_text')}
          loading={loading}
          onClick={(): void => presenter.onNextClick(authProvider)}
        />
        <div className={styles.spacer2} />

        <div className={clsx('claim-landing-footer-space', styles.footerSpace)} />
        <Label
          className={clsx('claim-landing-capture-footer', styles.footer)}
          text={t('claim.landing.footer')}
          variant="footer1"
        />
      </div>
    </div>
  );
}

export default withTranslation()(View);
