// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ofCtsW0_3OgJjsSAb2fA{width:100%;margin-top:6px;margin-bottom:6px;display:flex;flex-direction:column;flex-shrink:0;flex-grow:0}.aKcjm0nWBpMJ6QYUqemw{padding-left:0px;margin-bottom:8px;font-size:12px;color:#333}`, "",{"version":3,"sources":["webpack://./src/components/checkbox/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,cAAA,CACA,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,WAAA,CAGF,sBACE,gBAAA,CACA,iBAAA,CACA,cAAA,CACA,UAAA","sourcesContent":[".outerContainer {\n  width: 100%;\n  margin-top: 6px;\n  margin-bottom: 6px;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  flex-grow: 0;\n}\n\n.helperText {\n  padding-left: 0px;\n  margin-bottom: 8px;\n  font-size: 12px;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `ofCtsW0_3OgJjsSAb2fA`,
	"helperText": `aKcjm0nWBpMJ6QYUqemw`
};
export default ___CSS_LOADER_EXPORT___;
