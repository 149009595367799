import React, { ReactNode } from 'react';
import { CircularProgress, Stack, Typography } from '@mui/joy';

function Spinner({ children }: { children: ReactNode }): JSX.Element {
  return (
    <Stack
      direction="column"
      padding="16px"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%', height: '100%' }}
    >
      <CircularProgress sx={{ mb: '12px' }} />
      {children && <Typography level="body-xs">{children}</Typography>}
    </Stack>
  );
}

export default Spinner;
