import { ThemeStyle } from '../../types/theme';

export default function createStyles(theme: ThemeStyle): string[] {
  const backgroundColor = theme.palette?.background;

  const styles: string[] = [];

  if (backgroundColor && backgroundColor.length > 0) {
    styles.push(`.country-picker-content {background-color: ${backgroundColor};}`);
    styles.push(`.country-picker-list-item {background-color: ${backgroundColor};}`);
  }

  return styles;
}
