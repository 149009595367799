import { ThemeStyle, FontStyle, TypographyStyle } from '../../types/theme';

export default function createStyles(config: ThemeStyle): string[] {
  const styles: string[] = [];

  const { typography, palette } = config;

  if (typography) {
    const formFactors: [string, TypographyStyle][] = Object.entries(typography);
    formFactors.forEach(([device, style]) => {
      const entries: [string, FontStyle][] = Object.entries(style);
      entries.forEach(([key, value]) => {
        let rules = '';

        if (value.font_family && value.font_family.length > 0) {
          rules += `font-family:${value.font_family};`;
        }
        if (value.font_size && value.font_size?.length > 0) {
          rules += `font-size:${value.font_size};`;
        }
        if (value.font_weight && value.font_weight?.length > 0) {
          rules += `font-weight:${value.font_weight};`;
        }
        if (value.font_color && value.font_color?.length > 0) {
          rules += `color:${value.font_color};`;
        }

        styles.push(`.label.${device}.${key} {${rules}}`);

        if (palette?.link) {
          const { unvisited, visited, hover, active } = palette.link || {};

          if (unvisited && unvisited?.length > 0) {
            styles.push(`.label.${device}.${key} > a:link { color: ${unvisited}}`);
          }

          if (visited && visited?.length > 0) {
            styles.push(`.label.${device}.${key} > a:visited { color: ${visited}}`);
          }

          if (hover && hover?.length > 0) {
            styles.push(`.label.${device}.${key} > a:hover { color: ${hover}}`);
          }

          if (active && active?.length > 0) {
            styles.push(`.label.${device}.${key} > a:active { color: ${active}}`);
          }
        }
      });
    });
  }

  if (palette) {
    if (palette.primary) {
      styles.push(`.label.mobile.primary { color: ${palette.primary}; }`);
      styles.push(`.label.desktop.primary { color: ${palette.primary}; }`);
    }

    if (palette.success) {
      styles.push(`.label.mobile.success { color: ${palette.success}; }`);
      styles.push(`.label.desktop.success { color: ${palette.success}; }`);
    }

    if (palette.info) {
      styles.push(`.label.mobile.info { color: ${palette.info}; }`);
      styles.push(`.label.desktop.info { color: ${palette.info}; }`);
    }

    if (palette.warning) {
      styles.push(`.label.mobile.warning { color: ${palette.warning}; }`);
      styles.push(`.label.desktop.warning { color: ${palette.warning}; }`);
    }

    if (palette.error) {
      styles.push(`.label.mobile.error { color: ${palette.error}; }`);
      styles.push(`.label.desktop.error { color: ${palette.error}; }`);
    }
  }

  return styles;
}
