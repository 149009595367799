import React from 'react';
import BaseModel from '../../../util/model';

export interface StateModel {
  loading: boolean;
  allowPhone: boolean;
  allowEmail: boolean;
  showFacebook: boolean;
  showGoogle: boolean;
  showOutsideOauth: boolean;
  showWalletConnect: boolean;
  showProve: boolean;
  oauthOnly: boolean;
  advertiserName: string;
  showPrimaryConsent: boolean;
  showMarketingConsent: boolean;
  marketingConsentTerms: string;
  isConsentPopupOpen: boolean;
  collapseCard: boolean;
  hideAuth: boolean;
}

export default class Model extends BaseModel implements StateModel {
  static initialState: StateModel = {
    loading: false,
    allowPhone: false,
    allowEmail: false,
    showFacebook: false,
    showGoogle: false,
    showOutsideOauth: false,
    showWalletConnect: true,
    showProve: false,
    oauthOnly: false,
    advertiserName: '',
    showPrimaryConsent: true,
    showMarketingConsent: false,
    marketingConsentTerms: '',
    isConsentPopupOpen: false,
    collapseCard: false,
    hideAuth: false,
  };

  constructor(component: React.Component) {
    super(component, Model.initialState);
  }

  // Getters and Setters

  get loading(): boolean {
    const { loading } = this.value;
    return loading;
  }

  set loading(value: boolean) {
    this.value = { loading: value };
  }

  get allowPhone(): boolean {
    const { allowPhone } = this.value;
    return allowPhone;
  }

  set allowPhone(value: boolean) {
    this.value = { allowPhone: value };
  }

  get allowEmail(): boolean {
    const { allowEmail } = this.value;
    return allowEmail;
  }

  set allowEmail(value: boolean) {
    this.value = { allowEmail: value };
  }

  get showFacebook(): boolean {
    const { showFacebook } = this.value;
    return showFacebook;
  }

  set showFacebook(value: boolean) {
    this.value = { showFacebook: value };
  }

  get showGoogle(): boolean {
    const { showGoogle } = this.value;
    return showGoogle;
  }

  set showGoogle(value: boolean) {
    this.value = { showGoogle: value };
  }

  get showOutsideOauth(): boolean {
    const { showOutsideOauth } = this.value;
    return showOutsideOauth;
  }

  set showOutsideOauth(value: boolean) {
    this.value = { showOutsideOauth: value };
  }

  get showWalletConnect(): boolean {
    const { showWalletConnect } = this.value;
    return showWalletConnect;
  }

  set showWalletConnect(value: boolean) {
    this.value = { showWalletConnect: value };
  }

  get showProve(): boolean {
    const { showProve } = this.value;
    return showProve;
  }

  set showProve(value: boolean) {
    this.value = { showProve: value };
  }

  get oauthOnly(): boolean {
    const { oauthOnly } = this.value;
    return oauthOnly;
  }

  set oauthOnly(value: boolean) {
    this.value = { oauthOnly: value };
  }

  get advertiserName(): string {
    const { advertiserName } = this.value;
    return advertiserName;
  }

  set advertiserName(value: string) {
    this.value = { advertiserName: value };
  }

  get showPrimaryConsent(): boolean {
    const { showPrimaryConsent } = this.value;
    return showPrimaryConsent;
  }

  set showPrimaryConsent(value: boolean) {
    this.value = { showPrimaryConsent: value };
  }

  get showMarketingConsent(): boolean {
    const { showMarketingConsent } = this.value;
    return showMarketingConsent;
  }

  set showMarketingConsent(value: boolean) {
    this.value = { showMarketingConsent: value };
  }

  get marketingConsentTerms(): string {
    const { marketingConsentTerms } = this.value;
    return marketingConsentTerms;
  }

  set marketingConsentTerms(value: string) {
    this.value = { marketingConsentTerms: value };
  }

  get isConsentPopupOpen(): boolean {
    const { isConsentPopupOpen } = this.value;
    return isConsentPopupOpen;
  }

  set isConsentPopupOpen(value: boolean) {
    this.value = { isConsentPopupOpen: value };
  }

  get collapseCard(): boolean {
    const { collapseCard } = this.value;
    return collapseCard;
  }

  set collapseCard(value: boolean) {
    this.value = { collapseCard: value };
  }

  get hideAuth(): boolean {
    const { hideAuth } = this.value;
    return hideAuth;
  }

  set hideAuth(value: boolean) {
    this.value = { hideAuth: value };
  }
}
