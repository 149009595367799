import React from 'react';
import OtpScreen from '../../../views/view-otp';
import { FormattedToken, LocalePrefix } from '../../../types/common';
import Log from '../../../util/log';

interface IndexProps {
  formattedToken: FormattedToken;
  onBack: VoidFunction;
}

function index(props: IndexProps): JSX.Element {
  const { formattedToken, onBack } = props;

  return (
    <OtpScreen
      formattedToken={formattedToken}
      onBack={onBack}
      onOtpResend={(token: string): Promise<string> => {
        const params = {
          user: token,
        };

        return VlabsUser.go(
          '/confirm',
          params,
          (_, notificationType) => {
            Log.info('notificationType', notificationType);
            return notificationType;
          },
          (error) => {
            Log.error(error);
            throw error as any;
          }
        );
      }}
      onOtpContinue={(token: string, otp: string): Promise<string> => {
        const params = {
          user: token,
          password: otp,
        };

        return VlabsUser.go(
          '/confirm',
          params,
          (_, notificationType) => {
            Log.info('notificationType', notificationType);
            return notificationType;
          },
          (error) => {
            Log.error(error);
            throw error as any;
          }
        );
      }}
      localePrefix={LocalePrefix.claim}
    />
  );
}

export default index;
