import React from 'react';
import { withTranslation } from 'react-i18next';
import useStateMachine, { t } from '@cassiozen/usestatemachine';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import IdentityChallenge from './identity-challenge';
import IdentityForm, { IdentityFormData } from './identity-form';
import InstantLinkSent from './instant-link-sent';
import { Config, FormItem, LanguagePolicies, RawFormItem } from '../../types/config';
import ErrorPopup from '../error';
import Spinner from '../../components-v2/spinner';
import setupIframe from './iframe-listener';
import { plusPrefixPhoneNumber } from '../../util/validation';

dayjs.extend(customParseFormat);

/*
                                                                    State Management
--------------------------------------------------------------------------------------------------------------------------------------------------------------
Properties                  | Continuous (no state loss)                          | Discontinuous (state loss e.g. out-of-band Instant Link)
--------------------------------------------------------------------------------------------------------------------------------------------------------------
                            |                                                     |  >>> Before disconnect <<<
--------------------------------------------------------------------------------------------------------------------------------------------------------------
session_id:                 | Source: Returned in MobileAuth start                | Source: Query param in final target url 
                            | Set in FSM state, added to top-level payload        | Extracted after load, passed into flow, set in FSM state, top-level payload
--------------------------------------------------------------------------------------------------------------------------------------------------------------
draft_id:                   | Source: Returned in MobileAuth start                | Source: Query param in final target url 
                            | Set in FSM state, added to top-level payload        | Extracted after load, passed into flow, set in FSM state, top-level payload
--------------------------------------------------------------------------------------------------------------------------------------------------------------
TODO: Remove - pii should not be added as params to the Instant Link url.
phone_number:               | Source: User input                                  | Source: State query param in final target url
                            | Set in FSM state, added to top-level payload        | Extracted after load, passed into flow, set in FSM state, top-level payload
--------------------------------------------------------------------------------------------------------------------------------------------------------------
dob:                        | Source: User input                                  | Source: State query param in final target url
                            | Set in FSM state, added to top-level payload        | Extracted after load, passed into flow, set in FSM state, top-level payload
--------------------------------------------------------------------------------------------------------------------------------------------------------------
continuation form items     | Source: Props (from prior user input)               | Source: State query param in final target url
  (e.g. pre-auth iframe)    | Merged with collected form items, added to          | Merged with collected form items, added to
                            |   top-level form_items (api maps to params)         |   top-level form_items (api maps to params)
--------------------------------------------------------------------------------------------------------------------------------------------------------------
lab query params            | Source: Present in url query param                  | Source: URL query param
  (e.g ?vlabs=foo)          | Handled by vlabs user sdk, added to `payload.params`| vlabs user sdk builds redirect_uri to include search param. Handled by 
                            |                                                     |   vlabs user sdk, added to `payload.params`     
--------------------------------------------------------------------------------------------------------------------------------------------------------------
                            |                                                     |  >>> After disconnect <<<
--------------------------------------------------------------------------------------------------------------------------------------------------------------
collected form items        | Source: User input                                  | Source: User input
                            | Merged with collected form items, added to          | Merged with collected form items, added to
                            |   top-level form_items (api maps to params)         |  top-level form_items (api maps to params)
--------------------------------------------------------------------------------------------------------------------------------------------------------------

* Instant Link flow places all state in the final redirect url, this removes the flaky reliance on local storage.
** form_items are used instead of additional_params - this means the api maps into params (instead of the vlabs user sdk)

*/

interface FlowProps {
  page: 'login' | 'claim';
  config: Config;
  language?: string;
  languagePolicies?: LanguagePolicies;
  showProveFallback: boolean;
  continuationSessionId?: string | null;
  continuationDraftId?: string | null;
  continuationPhoneNumber?: string;
  continuationDob?: string;
  continuationFormItems?: RawFormItem[];
  onBack: (reason?: 'error') => void;
}

interface MobileAuthStartParams {
  connect: 'prove';
  flow: 'mobile_auth_start';
  language?: string;
  policies_accepted?: LanguagePolicies;
  prove_env?: 'staging' | 'production';
  // FIXME: Remove before go-live
  unsafe_ip?: string;
}

interface MobileAuthFinishParams {
  session_id: string;
  connect: 'prove';
  flow: 'mobile_auth_finish';
  connection: { vfp: string };
}

interface InstantLinkStartParams {
  session_id: string;
  draft_id: string | null;
  connect: 'prove';
  flow: 'instant_link_start';
  policies_accepted?: LanguagePolicies;
  phone_number: string;
  /**
   * State which must survive a disconnect.
   *
   * Instant Link is a disconnected flow (the link could launch to a different location)
   * causing all state collected before creating the link to be lost e.g. lab search query params
   * pre-auth iframe from item, etc.
   */
  state?: Record<string, string>;
  // FIXME: Remove before go-live
  unsafe_ip?: string;
}

interface TrustCheckParams {
  session_id: string;
  phone_number: string;
}

/**
 * - `birthday` - handled server-side (collected on pre-fill, or input on before verify)
 * - `policies_accepted` - handled server-side (draft consent)
 */
interface ConnectFinalParams {
  /** Vlabs API expects + prefix */
  phone_number: string;
  session_id: string;
  draft_id: string;
  connect: 'prove';
  form_items: RawFormItem[];
  language: string;
}

const getIp = (): Promise<string> => {
  let cleanUp: (() => void) | null = null;
  return new Promise((resolve, reject) => {
    // create iframe, listen for message from dataSource
    cleanUp = setupIframe({
      title: 'get-ip',
      dataSource: 'get-ip-frame',
      src: 'https://cdn-alpha.smartmedialabs.io/v/prove/get-ip.html',
      onMessageReceived: (event) => {
        // clean up iframe
        cleanUp?.();
        if (event.data.ip) {
          resolve(event.data.ip);
        } else {
          reject(new Error('No ip detected'));
        }
      },
    });
  });
};

function FlowView(props: FlowProps): JSX.Element {
  const {
    page,
    config,
    language,
    languagePolicies,
    continuationDob,
    continuationPhoneNumber,
    continuationSessionId,
    continuationDraftId,
    continuationFormItems,
    showProveFallback,
    onBack,
  } = props;

  // decide on starting point
  let startPoint: 'setup' | 'trust_check';
  startPoint = 'setup';

  // currently, the instant link finish (done by the sdk) is the only flow which doesn't start from the beginning
  if (
    continuationSessionId &&
    continuationSessionId.length > 0 &&
    continuationPhoneNumber &&
    continuationPhoneNumber.length > 0 &&
    continuationDraftId &&
    continuationDraftId.length > 0
  ) {
    startPoint = 'trust_check';
  }

  console.log(`[Prove flow] Start point: ${startPoint}`);

  // continuation dob comes from 1) age-gate or pre-auth iframe which is in YYYY-MM-DD format
  // or 2) Instant Link state which is in MM/DD format
  function formatDob(dob: string): Dayjs {
    return dayjs(dob, ['YYYY-MM-DD', 'MM/DD']);
  }

  // build below-the-fold form items
  const initialFormItems: FormItem[] =
    config.claim.form_items?.map((item) => {
      const initialItem = item;
      // Set the dropdowns value as null (rather than undefined) to make the component controlled
      if (initialItem.type === 'dropdown' && initialItem.value === undefined) {
        initialItem.value = null;
      }
      return initialItem;
    }) ?? [];

  // setup fsm initial state
  const initialState = {
    sessionId: continuationSessionId ?? null,
    draftId: continuationDraftId ?? null,
    instantLinkAuthURL: null,
    vfp: null,
    unsafeIP: null,
    challenge: {
      formattedPhoneNumber: continuationPhoneNumber ?? '',
      phoneNumber: continuationPhoneNumber ?? '',
      phoneReadOnly: false,
      dob: continuationDob ? formatDob(continuationDob) : null,
      dobValid: !!continuationDob,
      phoneValid: false,
    },
    pii: {
      preFilledError: null,
      preFilled: false,
      piiEdited: false,
      phoneNumber: continuationPhoneNumber ?? null,
      dob: continuationDob ? formatDob(continuationDob) : null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      isPhoneValid: !!continuationPhoneNumber,
      isDobValid: !!continuationDob,
      isFirstValid: false,
      isLastValid: false,
      isFormValid: false,
    },
    form: {
      surfaceExit: false,
    },
    formItems: initialFormItems,
    possession: startPoint === 'trust_check', // assume possession for session continuation
    reputation: false,
    ownership: false,
  };

  // initialize state machine
  const [machine, send] = useStateMachine({
    schema: {
      context: t<{
        sessionId: string | null;
        draftId: string | null;
        instantLinkAuthURL: string | null;
        vfp: string | null;
        unsafeIP: string | null;
        possession: boolean;
        reputation: boolean;
        ownership: boolean;
        challenge: {
          formattedPhoneNumber: string;
          phoneNumber: string;
          phoneReadOnly: boolean; // Don't allow edits if obtained via Mobile Auth
          dob: Dayjs | null;
          dobValid: boolean;
          phoneValid: boolean;
        };
        pii: {
          preFilledError: string | null;
          preFilled: boolean;
          piiEdited: boolean;
          phoneNumber: string | null;
          dob: Dayjs | null;
          firstName: string | null;
          lastName: string | null;
          emailAddress: string | null;
          isPhoneValid: boolean;
          isDobValid: boolean;
          isFirstValid: boolean;
          isLastValid: boolean;
          isFormValid: boolean;
        };
        form: {
          surfaceExit: boolean;
        };
        formItems: FormItem[];
      }>(),
      events: {
        MOBILE_AUTH_SUCCESS: t<{ phoneNumber: string }>(),
        UPDATE_CHALLENGE: t<{
          phoneNumber?: string;
          validationError?: string | null;
          dob?: Dayjs | null;
        }>(),
        PREFILL_EDIT: t<{ data: IdentityFormData | null }>(),
        FORM_EDIT_PII: t<{ data: IdentityFormData | null }>(),
        FORM_EDIT_ADDITIONAL: t<{ item: FormItem; value: any }>(),
        UNVERIFIED: t<{ data: null }>(),
      },
    },
    context: { ...initialState },
    verbose: config.prove?.environment === 'staging',
    initial: startPoint,
    states: {
      restart: {
        effect({ setContext }) {
          // reset state
          setContext(() => ({ ...initialState }));
          onBack?.();
        },
      },
      setup: {
        on: {
          NEXT: 'fetching_device_ip',
        },
        effect({ setContext }) {
          // reset state
          setContext(() => ({ ...initialState })).send('NEXT');
        },
      },
      fetching_device_ip: {
        on: {
          NEXT: 'starting_mobile_auth',
          ERROR: 'starting_mobile_auth',
        },
        effect({ setContext }) {
          const fetchIP = async (): Promise<void> => {
            try {
              const unsafeIP = await getIp();
              console.log('unsafeIP', unsafeIP);
              setContext((c) => ({ ...c, unsafeIP })).send('NEXT');
            } catch (error) {
              console.error('Error fetching IP:', error);
              send('ERROR');
            }
          };
          fetchIP();
        },
      },
      starting_mobile_auth: {
        on: {
          RECEIVED_VFP: {
            target: 'finishing_mobile_auth',
            guard({ context }) {
              if (context.vfp) return true;
              return false;
            },
          },
          START_FALLBACK: 'challenging',
          RESTART: 'restart',
        },
        effect({ setContext, context }) {
          let startPayload: MobileAuthStartParams = {
            language,
            connect: 'prove',
            flow: 'mobile_auth_start',
            unsafe_ip: context.unsafeIP ?? undefined,
          };

          if (page === 'claim' && !languagePolicies) {
            console.error('Missing required language policies');
            ErrorPopup.show({ code: 'prove_flow_failed' }, () => onBack('error'));
            return;
          }
          startPayload = {
            ...startPayload,
            policies_accepted: languagePolicies,
            prove_env: config.prove?.environment,
          };

          /*
            Pros:
            - Transparent to the user.
            - No CORS, because we don't perform a fetch request for the data (cross domain). Rather, it's iframed (sandboxed using standard message passing).

            Cons:
            - If the redirect target url has security set: frame-ancestor which specifies valid parents (us) who can embed the frame
            - Also x-frame-options (older)
            */

          // promise wrapper to extract the vfp (hidden iframe, loads url, follows redirect to carrier's auth page)
          const extractVFP = (url: string): Promise<string> => {
            let cleanUp: (() => void) | null = null;
            return new Promise((resolve, reject) => {
              // create iframe, listen for message from dataSource
              cleanUp = setupIframe({
                title: 'prove-mobile-auth-iframe',
                dataSource: 'prove-mobile-auth-redirect',
                src: url,
                onMessageReceived: (event) => {
                  // clean up iframe
                  cleanUp?.();
                  if (event.data.vfp) {
                    resolve(event.data.vfp);
                  } else {
                    reject(new Error('Unable to extract VFP'));
                  }
                },
              });
            });
          };

          // make api request
          VlabsUser.go(
            '/connect',
            startPayload,
            (data): void => {
              if (data.success) {
                console.log('response /connect Success:', data);
                setContext((c) => ({
                  ...c,
                  sessionId: data.session_id,
                  draftId: data.draft_id,
                }));
                console.log(`${machine.value} > iframe will redirect to`, data.redirect_target_url);

                // extract vfp
                extractVFP(data.redirect_target_url)
                  .then((vfp) => {
                    console.log(`${machine.value} > Extracted vfp:`, vfp);
                    setContext((c) => ({ ...c, vfp })).send('RECEIVED_VFP');
                  })
                  .catch((error) => {
                    console.error(`${machine.value} > Error:`, error);
                  });
              } else if (!data.success && data.session_id) {
                console.log('response /connect Error:', data);
                setContext((c) => ({
                  ...c,
                  sessionId: data.session_id,
                  draftId: data.draft_id,
                })).send('START_FALLBACK');
              }
            },
            (error): void => {
              console.error(
                'response /connect mobile_auth_start Error:',
                error,
                JSON.stringify(error)
              );
              ErrorPopup.show(error);
              send('RESTART');
            }
          ).catch(() => {
            ErrorPopup.showDefault(() => onBack('error'));
          });
        },
      },
      finishing_mobile_auth: {
        on: {
          CHECK_IF_TRUSTED: 'trust_check',
          START_FALLBACK: 'challenging',
        },
        effect({ context, setContext }) {
          // state validation
          if (!context.sessionId || !context.vfp) {
            console.error('Validation error: Missing session_id or vfp');
            ErrorPopup.show({ code: 'prove_flow_failed' }, () => onBack('error'));
            return;
          }

          // build params
          const finishParams: MobileAuthFinishParams = {
            connect: 'prove',
            flow: 'mobile_auth_finish',
            connection: { vfp: context.vfp },
            session_id: context.sessionId,
          };

          // finish mobile auth
          VlabsUser.go(
            '/connect',
            finishParams,
            (data): void => {
              console.log('response /connect mobile_auth_finish:', data);

              // check for success
              if (data.success && data.phone_number) {
                // update context and send to trust check
                // mobile number from Prove is assumed to be valid
                setContext((c) => ({
                  ...c,
                  possession: true,
                  challenge: {
                    ...c.challenge,
                    phoneNumber: data.phone_number,
                    formattedPhoneNumber: data.phone_number,
                    phoneReadOnly: true,
                    phoneValid: true,
                  },
                  pii: {
                    ...c.pii,
                    phoneNumber: data.phone_number,
                    isPhoneValid: true,
                  },
                })).send('CHECK_IF_TRUSTED');
              } else {
                console.error('response /connect mobile_auth_finish Error:', data);
                // remove vfp and start fallback
                setContext((c) => ({ ...c, vfp: null })).send('START_FALLBACK');
              }
            },
            (error): void => {
              console.error('response /connect mobile_auth_finish Error:', error);
              // remove vfp and start fallback
              setContext((c) => ({ ...c, vfp: null })).send('START_FALLBACK');
            }
          );
        },
      },
      starting_instant_link: {
        on: {
          LINK_SENT: 'instant_link_sent',
        },
        effect({ context, setContext }) {
          // state validation
          if (!context.sessionId) {
            console.error('Validation error: Missing session_id');
            ErrorPopup.show({ code: 'prove_flow_failed' }, () => onBack('error'));
            return;
          }
          // claim page must send draft id
          if (!context.draftId && page === 'claim') {
            console.error('Validation error: Missing draft_id');
            ErrorPopup.show({ code: 'prove_flow_failed' }, () => onBack('error'));
            return;
          }

          // build payload
          const payload: InstantLinkStartParams = {
            connect: 'prove',
            flow: 'instant_link_start',
            phone_number: context.challenge.formattedPhoneNumber,
            session_id: context.sessionId,
            draft_id: context.draftId,
            unsafe_ip: context.unsafeIP ?? undefined,
          };

          // build state for disconnect
          // state: dob
          const dob = context.challenge.dob?.format('MM/DD');
          if (dob) {
            payload.state = { dob };
          }
          // state: form items
          if (continuationFormItems) {
            const formItemsEncoded = encodeURIComponent(JSON.stringify(continuationFormItems));
            payload.state = { ...payload.state, form_items: formItemsEncoded };
          }

          VlabsUser.go(
            '/connect',
            payload,
            (data): void => {
              console.log('response /connect data:', data);

              if (data.status_code === 0) {
                // FIXME: Platform will remove before go-live
                if (config.prove?.environment === 'staging') {
                  const instantLinkAuthURL = data.temporary_url;
                  if (instantLinkAuthURL) {
                    setContext((c) => ({ ...c, instantLinkAuthURL }));
                  }
                }
                send('LINK_SENT');
              } else {
                console.error('response /connect error:', data);
                ErrorPopup.show({ code: 'prove_flow_failed' }, () => {
                  onBack('error');
                });
              }
            },
            (error): void => {
              console.error('response /connect error:', error);
              ErrorPopup.show(error, () => {
                onBack('error');
              });
            }
          ).catch(() => {
            ErrorPopup.showDefault(() => onBack('error'));
          });
        },
      },
      instant_link_sent: {
        on: {
          RESEND: 'challenge_captured',
          CHECK_IF_TRUSTED: 'trust_check',
        },
      },
      trust_check: {
        on: {
          IDENTIFY: 'prefill_identifying',
          CHALLENGE: 'challenging',
          PII_INPUT: 'form_pristine',
        },
        effect({ setContext, context }) {
          // state validation
          if (!context.sessionId) {
            console.error('Validation error: Missing session_id');
            ErrorPopup.show({ code: 'prove_flow_failed' }, () => onBack('error'));
            return;
          }

          // build payload
          const payload: TrustCheckParams = {
            session_id: context.sessionId,
            phone_number: context.challenge.formattedPhoneNumber,
          };

          // make request
          VlabsUser.go(
            '/trust',
            payload,
            (data): void => {
              console.log('response /trust data:', data);

              const isTrusted = data.trusted;
              if (isTrusted) {
                setContext((c) => ({ ...c, reputation: true }));
                if (context.challenge.dob) {
                  send('IDENTIFY');
                } else {
                  // User still needs to capture dob
                  send('CHALLENGE');
                }
              } else {
                setContext((c) => ({ ...c, reputation: false }));
                // If the users number is not trusted, pre-fill is not possible, they must manually capture their data for an ownership check
                send('PII_INPUT');
              }
            },
            (error): void => {
              console.error('response /trust error:', error);
              ErrorPopup.show(error, () => {
                onBack('error');
              });
            }
          ).catch(() => {
            ErrorPopup.showDefault(() => onBack('error'));
          });
        },
      },
      challenging: {
        on: {
          UPDATE_CHALLENGE: 'challenge_updated',
        },
      },
      challenge_updated: {
        on: {
          UPDATE_CHALLENGE: 'challenge_updated',
          SUBMIT_CHALLENGE: 'challenge_captured',
        },
        // validate form
        effect({ context, setContext, event }) {
          // dob
          let { dobValid } = context.challenge;
          if (event.dob !== undefined) {
            dobValid = (event.dob && event.dob.isValid()) ?? false;
          }
          // phone
          let { phoneValid } = context.challenge;
          if (event.validationError !== undefined) {
            phoneValid = !(event.validationError && event.validationError.length > 0);
          }

          setContext((c) => ({
            ...c,
            challenge: {
              ...c.challenge,
              formattedPhoneNumber: event.phoneNumber ?? c.challenge.formattedPhoneNumber,
              phoneNumber: event.phoneNumber ?? c.challenge.phoneNumber,
              dob: event.dob ?? c.challenge.dob,
              phoneValid,
              dobValid,
            },
          }));
        },
      },
      challenge_captured: {
        on: {
          CHECK_POSSESSION: {
            target: 'starting_instant_link',
            guard({ context }) {
              return !!context.challenge.formattedPhoneNumber;
            },
          },
          IDENTIFY: {
            target: 'prefill_identifying',
            guard({ context }) {
              return !!context.challenge.formattedPhoneNumber && !!context.challenge.dob;
            },
          },
        },

        effect({ context }) {
          if (context.possession) {
            send('IDENTIFY');
          } else {
            send('CHECK_POSSESSION');
          }
        },
      },
      prefill_identifying: {
        // Non-visual
        on: {
          IDENTIFIED: 'form_pristine',
          NOT_IDENTIFIED: 'form_pristine',
        },
        effect({ context, setContext }) {
          // build payload
          const payload = {
            session_id: context.sessionId,
            pii: {
              phone_number: context.challenge.formattedPhoneNumber,
              dob: context.challenge.dob?.format('MM/DD'),
            },
          };

          // make request
          VlabsUser.go(
            '/identity',
            payload,
            async (data): Promise<void> => {
              console.log('response /identity data:', data);

              if (data.status_code === 0) {
                const {
                  dob,
                  phone_number: phoneNumber,
                  first_name: firstName,
                  last_name: lastName,
                } = data;

                setContext((c) => ({
                  ...c,
                  pii: {
                    ...c.pii,
                    phoneNumber,
                    dob: dayjs(dob, 'YYYY-MM-DD'), // Prove server returns in YYYY-MM-DD format
                    firstName,
                    lastName,
                    preFilled: true,
                  },
                })).send('IDENTIFIED');
              } else if (data.status_code === 1010 || data.status_code === 1012) {
                // if no data available, allow to proceed to manual entry
                setContext((c) => ({
                  ...c,
                  pii: {
                    ...c.pii,
                    preFilled: false,
                    preFilledError: data.status_code,
                  },
                })).send('NOT_IDENTIFIED');
              } else {
                console.error('response /identity error:', data.status_code);
                ErrorPopup.show({ code: 'prove_prefill_failed' }, () => {
                  onBack('error');
                });
              }
            },
            (error): void => {
              console.error('response /identity error:', error);
              ErrorPopup.show(error, () => {
                // Allow user to continue with manual entry
                send('NOT_IDENTIFIED');
              });
            }
          );
        },
      },
      form_pristine: {
        on: {
          FORM_EDIT_PII: 'form_editing',
          FORM_EDIT_ADDITIONAL: 'form_editing',
          FORM_SUBMIT: 'form_submitting',
        },

        effect({ context, setContext }) {
          // if from is prefilled, trust the data
          if (context.pii.preFilled) {
            setContext((c) => ({
              ...c,
              pii: {
                ...c.pii,
                isDobValid: true,
                isPhoneValid: true,
                isFormValid: true,
              },
            }));
          }
        },
      },
      form_editing: {
        on: {
          FORM_SUBMIT: 'form_submitting',
          FORM_EDIT_PII: 'form_editing',
          FORM_EDIT_ADDITIONAL: 'form_editing',
        },

        effect({ context, setContext, event }) {
          if (event.type === 'FORM_EDIT_PII') {
            // null event signifies starting to edit
            if (event.data === null) {
              setContext((c) => ({
                ...c,
                pii: {
                  ...c.pii,
                  piiEdited: true,
                },
              }));
              return;
            }

            const firstNameValid = !!(event.data?.firstName && event.data.firstName.length > 0);
            const lastNameValid = !!(event.data?.lastName && event.data.lastName.length > 0);
            const dobValid = (event.data?.dob && event.data?.dob.isValid()) ?? false;

            let phoneValid = context.pii.isPhoneValid;
            if (event.data.phoneValidationError !== undefined) {
              phoneValid = !(
                event.data.phoneValidationError && event.data.phoneValidationError.length > 0
              );
            }

            const isFormValid = firstNameValid && lastNameValid && phoneValid && dobValid;

            setContext((c) => ({
              ...c,
              pii: {
                ...c.pii,
                phoneNumber: event.data?.phoneNumber ?? null,
                dob: event.data?.dob ?? null,
                firstName: event.data?.firstName ?? null,
                lastName: event.data?.lastName ?? null,
                isPhoneValid: phoneValid,
                isDobValid: dobValid,
                isFirstValid: firstNameValid,
                isLastValid: lastNameValid,
                isFormValid,
              },
            }));
          } else if (event.type === 'FORM_EDIT_ADDITIONAL') {
            // Additional data has changed

            setContext((c) => {
              const itemIndex = c.formItems.findIndex((item) => item.id === event.item.id);
              if (!itemIndex) {
                return { ...c };
              }
              const itemForUpdate = c.formItems[itemIndex];
              console.log('update before', itemForUpdate, 'value', !!event.value);
              if (itemForUpdate.type === 'checkbox') {
                itemForUpdate.value = !!event.value;
              } else if (itemForUpdate.type === 'dropdown') {
                itemForUpdate.value = event.value;
              }
              console.log('update after', itemForUpdate, 'value', !!event.value);

              const updatedItems = c.formItems;
              updatedItems[itemIndex] = itemForUpdate;

              return {
                ...c,
                formItems: updatedItems,
              };
            });
          }
        },
      },
      form_submitting: {
        on: {
          FORM_SUBMIT: 'form_submitting',
          VERIFIED: 'creating_account',
          UNVERIFIED: 'form_editing',
        },

        effect({ context, setContext }) {
          // if the form was pre-filled, and no edits were made, send to create account, backend will ensure supplied info hasn't changed
          if (context.pii.preFilled && !context.pii.piiEdited) {
            send('VERIFIED');
            return;
          }

          if (!context.pii.phoneNumber) {
            send({ type: 'UNVERIFIED', data: null });
            return;
          }

          // build payload
          const payload = {
            session_id: context.sessionId,
            pii: {
              first_name: context.pii.firstName,
              last_name: context.pii.lastName,
              phone_number: context.pii.phoneNumber,
              email_address: context.pii.emailAddress,
              dob: context.pii.dob?.format('MM/DD'),
            },
          };

          // make request
          VlabsUser.go(
            '/verify',
            payload,
            (data): void => {
              console.log('response /verify data:', data);
              if (data.verified) {
                send('VERIFIED');
              } else {
                // surface alt auth method
                setContext((c) => ({ ...c, form: { surfaceExit: true } })).send({
                  type: 'UNVERIFIED',
                  data: null,
                });
                // show client side error, allow user to continue
                ErrorPopup.show({ code: 'prove_verify_failed' });
              }
            },
            (error): void => {
              console.error('response /verify error:', error);
              // surface alt auth method
              setContext((c) => ({ ...c, form: { surfaceExit: true } })).send({
                type: 'UNVERIFIED',
                data: null,
              });
              ErrorPopup.show(error, () => {
                onBack('error');
              });
            }
          );
        },
      },
      creating_account: {
        on: {},
        effect({ context }) {
          // validate inputs
          if (!context.sessionId || !context.draftId) {
            console.error('Validation error: Missing sessionId or draftId');
            onBack('error');
            return;
          }

          if (!context.pii.phoneNumber) {
            console.error('Validation error: Missing phone number');
            onBack('error');
            return;
          }

          // map collected values to raw (server format)
          const collectedFormItems: RawFormItem[] = context.formItems.map((item) => {
            return { id: item.id, value: item.value ?? null };
          });

          // merge with already-collected input form items
          const formItems: RawFormItem[] = [
            ...(continuationFormItems ?? []),
            ...collectedFormItems,
          ];

          // build payload
          const payload: ConnectFinalParams = {
            session_id: context.sessionId,
            draft_id: context.draftId,
            connect: 'prove',
            form_items: formItems,
            phone_number: plusPrefixPhoneNumber(context.pii.phoneNumber),
            language: language ?? '',
          };

          // make request
          VlabsUser.go(
            '/connect-prove',
            payload,
            (data): void => {
              console.log('response /connect-prove data:', data);
            },
            (error): void => {
              console.error('response /connect-prove error:', error);
              ErrorPopup.show({ code: 'prove_flow_error' }, () => onBack('error'));
            }
          );
        },
      },
    },
  });

  let stateText = '';
  switch (machine.value) {
    case 'starting_mobile_auth':
      stateText = 'Starting Mobile Auth';
      break;
    case 'finishing_mobile_auth':
      stateText = 'Finishing Mobile Auth';
      break;
    case 'starting_instant_link':
      stateText = 'Sending instant link';
      break;
    case 'instant_link_sent':
      stateText = 'Instant link sent';
      break;
    case 'trust_check':
      stateText = 'Checking Trust Score';
      break;
    case 'prefill_identifying':
      stateText = 'Prefilling';
      break;
    case 'creating_account':
      stateText = 'Creating your Account';
      break;
    default:
      stateText = '';
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {machine.value === 'restart' && <div>Error Page</div>}

      {(machine.value === 'fetching_device_ip' ||
        machine.value === 'starting_mobile_auth' ||
        machine.value === 'finishing_mobile_auth' ||
        machine.value === 'starting_instant_link' ||
        machine.value === 'trust_check' ||
        machine.value === 'prefill_identifying') && <Spinner>{stateText}</Spinner>}

      {machine.value === 'instant_link_sent' && (
        <InstantLinkSent
          instantLinkAuthURL={machine.context.instantLinkAuthURL}
          onResendClick={(): void => send('RESEND')}
        />
      )}

      {(machine.value === 'challenging' ||
        machine.value === 'challenge_updated' ||
        machine.value === 'challenge_captured') && (
        <IdentityChallenge
          page={page}
          loading={machine.value === 'challenge_captured'}
          phoneNumber={machine.context.challenge.phoneNumber!}
          phoneReadOnly={machine.context.challenge.phoneReadOnly}
          allowedRegions={config.token.regions ?? []}
          disableDobPicker={!!(continuationDob && continuationDob.length > 0)}
          dob={machine.context.challenge.dob}
          submitDisabled={
            !(
              machine.context.challenge.phoneValid &&
              (page === 'login' || (page === 'claim' && machine.context.challenge.dobValid))
            )
          }
          onChange={(phoneNumber, validationError, dob): void => {
            send({ type: 'UPDATE_CHALLENGE', phoneNumber, validationError, dob });
          }}
          onSubmit={(): void => send('SUBMIT_CHALLENGE')}
          onBack={(reason): void => onBack(reason)}
        />
      )}

      {(machine.value === 'form_pristine' ||
        machine.value === 'form_editing' ||
        machine.value === 'form_submitting' ||
        machine.value === 'creating_account') && (
        <IdentityForm
          page={page}
          loading={machine.value === 'form_submitting' || machine.value === 'creating_account'}
          prefilled={machine.context.pii.preFilled}
          piiReadOnly={!machine.context.pii.piiEdited && machine.context.pii.preFilled}
          submitDisabled={!machine.context.pii.isFormValid}
          phoneNumber={machine.context.pii.phoneNumber}
          dob={machine.context.pii.dob}
          firstName={machine.context.pii.firstName}
          lastName={machine.context.pii.lastName}
          emailAddress={machine.context.pii.emailAddress}
          surfaceExit={showProveFallback && machine.context.form.surfaceExit}
          onChangePii={(data): void => send({ type: 'FORM_EDIT_PII', data })}
          formItems={machine.context.formItems}
          onFormItemChange={(item, value): void => {
            send({ type: 'FORM_EDIT_ADDITIONAL', item, value });
          }}
          onSubmit={(): void => send('FORM_SUBMIT')}
          onBack={(reason): void => onBack(reason)}
        />
      )}
    </div>
  );
}

FlowView.defaultProps = {
  language: undefined,
  languagePolicies: undefined,
  continuationSessionId: undefined,
  continuationPhoneNumber: undefined,
  continuationDob: undefined,
  continuationDraftId: undefined,
  continuationFormItems: undefined,
};

export default withTranslation()(FlowView);
