// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b2KAcmi0RRmtZj5wyUM6{margin-bottom:10px;width:100%;display:flex;flex-direction:column;flex-shrink:0;flex-grow:0}.SyS58ji4tisXP0khfIZS{display:flex;align-items:center;gap:5px;margin-bottom:8px}.oAeqF_9ZcI8GpRaWwQsH{font-size:12px;color:#333}.LsF06v9mvyp0cvdqRZnD{margin-top:6px;padding-left:5px;font-size:12px;color:#333}`, "",{"version":3,"sources":["webpack://./src/features/claim/form-items-view/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,UAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,WAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,iBAAA,CAGF,sBACE,cAAA,CACA,UAAA,CAGF,sBACE,cAAA,CACA,gBAAA,CACA,cAAA,CACA,UAAA","sourcesContent":[".container {\n  margin-bottom: 10px;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  flex-shrink: 0;\n  flex-grow: 0;\n}\n\n.labelContainer {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  margin-bottom: 8px;\n}\n\n.label {\n  font-size: 12px;\n  color: #333;\n}\n\n.helperText {\n  margin-top: 6px;\n  padding-left: 5px;\n  font-size: 12px;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `b2KAcmi0RRmtZj5wyUM6`,
	"labelContainer": `SyS58ji4tisXP0khfIZS`,
	"label": `oAeqF_9ZcI8GpRaWwQsH`,
	"helperText": `LsF06v9mvyp0cvdqRZnD`
};
export default ___CSS_LOADER_EXPORT___;
