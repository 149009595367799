import i18n, { InitOptions, Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React from 'react';
import { initReactI18next } from 'react-i18next';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import {
  CssVarsProvider as JoyCssVarsProvider,
  extendTheme as extendJoyTheme,
  Theme as JoyTheme,
  PaletteRange,
} from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import LocaleEn from './resources/locales/en.json';
import AlertProvider from './util/alert/alert-provider';
import deepMerge from './util/deep-merge';
import loadScript from './util/load-script';
import Log from './util/log';
import createBaseViewStyles from './views/view-base/style-loader-theme';
import createButtonThemeStyles from './components/button/style-loader-theme';
import createCheckboxThemeStyle from './components/checkbox/style-loader-theme';
import createInputThemeStyle from './components/input/style-loader-theme';
import createTypographyThemeStyles from './components/label/style-loader-theme';
import createOTPInputThemeStyles from './components/otp-input/style-loader-theme';
import createSelectThemeStyle from './components/select/style-loader-theme';
import createAnchorViewThemeStyles from './views/view-anchor/style-loader-theme';
import createClaimMultiThemeStyle from './features/claim/style-loader-theme';
import createGeoBlockerThemeStyle from './features/geo-blocker/style-loader-theme';
import createLoginMultiThemeStyle from './features/login/style-loader-theme';
import createUnsubscribeMultiThemeStyle from './features/unsubscribe/style-loader-theme';
import createThemeStyle from './util/style-loader-theme';
import createCountrySelectTheme from './features/country-select/style-loader-theme';
import { AppPage } from './types/common';
import { Config } from './types/config';
import { sanitizeCdnUrl } from './util/validation';
import './util/dompurifyConfig';

interface AppProps {
  path: string;
  page: AppPage;
  children: (config: Config) => JSX.Element;
}

export default class App extends React.Component<AppProps, { config?: Config }> {
  config?: Config;

  joyTheme?: JoyTheme;

  // Needed for the date input
  materialTheme = materialExtendTheme();

  constructor(props: AppProps) {
    super(props);
    // Setup state
    this.state = {};
  }

  componentDidMount(): void {
    const { path, page } = this.props;
    fetch(`${path}public/config/config.json`, { method: 'GET', cache: 'no-store' })
      .then((result) => result.json())
      .then((config) => {
        const {
          global_config_path: globalConfigPath,
          locales,
          default_language: defaultLanguage,
        } = config;
        if (locales !== undefined && (locales === null || Array.isArray(locales))) {
          // eslint-disable-next-line no-param-reassign
          config.locales = {};
        }
        if (
          defaultLanguage === null ||
          (Array.isArray(defaultLanguage) && defaultLanguage.length === 0) ||
          defaultLanguage === ''
        ) {
          // eslint-disable-next-line no-param-reassign
          delete config.default_language;
        }
        if (globalConfigPath) {
          return fetch(`${globalConfigPath}`, { method: 'GET', cache: 'no-store' })
            .then((result) => result.json())
            .catch(() => ({}))
            .then((globalConfig) => {
              return deepMerge(globalConfig, config);
            });
        }
        return config;
      })
      .then(async (config) => {
        const { lab_sdk_url: labSdkUrl } = config;

        // Load the vlabs sdk
        if (labSdkUrl && labSdkUrl.length > 0) {
          await loadScript(labSdkUrl);
          return config;
        }

        // Load fallback (live cdn)
        const fallbackUrl =
          page === 'unsubscribe'
            ? `https://cdn.smartmedialabs.io/s/js/vlabs-unsubscribe.js`
            : `https://cdn.smartmedialabs.io/s/js/vlabs-user.js`;

        Log.error(
          `Config Error: Missing 'lab_sdk_url'. Falling back on hardcoded url ${fallbackUrl}`
        );

        await loadScript(fallbackUrl);
        return config;
      })
      .then(async (config) => {
        // Cookie scripts
        try {
          const { environment } = config;
          let oneTrustApiKey = '';
          if (window.location.hostname.endsWith('.smartmediawallet.app')) {
            if (environment === 'alpha') {
              oneTrustApiKey = '63de1bb4-71b3-4250-b289-91984ba6e0a3-test';
            } else {
              oneTrustApiKey = '63de1bb4-71b3-4250-b289-91984ba6e0a3';
            }
          } else if (window.location.hostname.endsWith('.smartwallet.app')) {
            if (environment === 'alpha') {
              oneTrustApiKey = '9c9cf5af-db55-47da-bf82-3b80f627e7b2-test';
            } else {
              oneTrustApiKey = '9c9cf5af-db55-47da-bf82-3b80f627e7b2';
            }
          } else if (environment === 'alpha') {
            oneTrustApiKey = 'cd2e990d-f529-43a0-9d9f-5b2c05e96047-test';
          } else {
            oneTrustApiKey = 'cd2e990d-f529-43a0-9d9f-5b2c05e96047';
          }
          await Promise.all([
            loadScript(`https://cdn.cookielaw.org/consent/${oneTrustApiKey}/OtAutoBlock.js`),
            loadScript('https://cdn.cookielaw.org/scripttemplates/otSDKStub.js', undefined, {
              'data-domain-script': oneTrustApiKey,
            }),
          ]);
        } catch (error: any) {
          Log.error('CookieLaw script(s) load failed.', error);
        }

        return config;
      })
      .then(async (config): Promise<Config> => {
        // generate style sheet from config
        const { style } = config;
        const { external, theme } = style;
        const sheet = document.createElement('style');
        document.body.appendChild(sheet);
        const styleSheet = sheet.sheet;
        if (styleSheet) {
          style.fonts?.forEach((font: any) => {
            styleSheet.insertRule(
              `@font-face {font-family: ${font?.family};src: url(${font.src});}`,
              styleSheet.cssRules.length
            );
          });

          if (theme) {
            createThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createTypographyThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createAnchorViewThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createBaseViewStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createButtonThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createOTPInputThemeStyles(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createInputThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createSelectThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createGeoBlockerThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createCheckboxThemeStyle(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            createCountrySelectTheme(theme).forEach((rule) => {
              styleSheet.insertRule(rule, styleSheet.cssRules.length);
            });

            // Page specific theme
            if (page === 'claim-multi') {
              createClaimMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            } else if (page === 'login-multi') {
              createLoginMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            } else if (page === 'unsubscribe-multi') {
              createUnsubscribeMultiThemeStyle(theme).forEach((rule) => {
                styleSheet.insertRule(rule, styleSheet.cssRules.length);
              });
            }
          }

          if (external?.length > 0) {
            await Promise.all(
              external.map((stylePath: string) => {
                return new Promise<Config | void>((resolve, reject) => {
                  const sanitizedStylePath = sanitizeCdnUrl(stylePath);
                  if (sanitizedStylePath === undefined) {
                    reject(new Error(`Style URL is untrusted: ${stylePath}`));
                    return;
                  }
                  const stylesheet = document.createElement('link');
                  stylesheet.rel = 'stylesheet';
                  stylesheet.type = 'text/css';
                  stylesheet.href = sanitizedStylePath.toString();
                  const timeout = setTimeout(() => {
                    resolve(config);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                  }, 3000);
                  stylesheet.onload = (): void => {
                    clearTimeout(timeout);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                    resolve();
                  };
                  stylesheet.onerror = (loadError): void => {
                    Log.error(loadError);
                    clearTimeout(timeout);
                    stylesheet.onload = null;
                    stylesheet.onerror = null;
                    resolve();
                  };
                  document.getElementsByTagName('head')[0].prepend(stylesheet);
                });
              })
            );
            return config;
          }
          return Promise.resolve(config);
        }
        Log.error('Config setup failed: Missing stylesheet');
        throw new Error('Config setup failed: Missing stylesheet');
      })
      .then((config) => {
        // Configure Joy UI theme

        const themePalette = config.style.theme?.palette;
        const themeButton = config.style.theme?.button;
        const themeInput = config.style.theme?.input;

        this.joyTheme = extendJoyTheme({
          // fontFamily: {
          //   display: 'Noto Sans', // applies to `h1`–`h4`
          //   body: 'Noto Sans', // applies to `title-*` and `body-*`
          // },
          cssVarPrefix: 'smt',
          breakpoints: {
            values: {
              xs: 0,
              sm: 600,
              md: 856, // Desktop
              lg: 1200,
              xl: 1536,
            },
          },
          colorSchemes: {
            light: {
              palette: {
                primary: {
                  solidColor: themeButton?.variant?.primary.active_font_color,
                  solidBg:
                    themeButton?.variant?.primary.background_color ??
                    themeButton?.variant?.primary.active_background_color,
                  solidActiveBg: themeButton?.variant?.primary.active_background_color,
                  solidActiveBorder: themeButton?.variant?.primary.active_border_color,
                  solidHoverBg:
                    themeButton?.variant?.primary.hover_background_color ??
                    themeButton?.variant?.primary.active_background_color,
                  solidDisabledColor: themeButton?.variant?.primary.inactive_font_color,
                  solidDisabledBg: themeButton?.variant?.primary.inactive_background_color,
                  solidDisabledBorder: themeButton?.variant?.primary.inactive_border_color,
                },
              },
            },
          },
          components: {
            JoyModalDialog: {
              styleOverrides: {
                root: {
                  maxWidth: 440,
                },
              },
            },
            JoyButton: {
              defaultProps: {
                fullWidth: true,
              },
              styleOverrides: {
                root: ({ ownerState }) => ({
                  ...(ownerState.size === 'lg' && {
                    ...(ownerState.variant !== 'plain' &&
                      ownerState.variant !== 'soft' && {
                        borderRadius: themeButton?.size?.large?.border_radius,
                        borderStyle: themeButton?.size?.large?.border_style,
                        borderWidth: themeButton?.size?.large?.border_thickness,
                      }),
                    height: themeButton?.size?.large?.height,
                    width: themeButton?.size?.large?.width,
                    lineHeight: themeButton?.size?.large?.line_height,
                    paddingLeft: themeButton?.size?.large?.padding_left,
                    paddingRight: themeButton?.size?.large?.padding_right,
                    paddingTop: themeButton?.size?.large?.padding_top,
                    paddingBottom: themeButton?.size?.large?.padding_bottom,
                    marginLeft: themeButton?.size?.large?.margin_left,
                    marginRight: themeButton?.size?.large?.margin_right,
                    marginTop: themeButton?.size?.large?.margin_top,
                    marginBottom: themeButton?.size?.large?.margin_bottom,
                    fontWeight: themeButton?.size?.large?.font_weight,
                    fontSize: themeButton?.size?.large?.font_size,
                    fontFamily: themeButton?.size?.large?.font_family,
                  }),
                  ...(ownerState.size === 'md' && {
                    ...(ownerState.variant !== 'plain' &&
                      ownerState.variant !== 'soft' && {
                        borderRadius: themeButton?.size?.medium?.border_radius,
                        borderStyle: themeButton?.size?.medium?.border_style,
                        borderWidth: themeButton?.size?.medium?.border_thickness,
                      }),
                    height: themeButton?.size?.medium?.height,
                    width: themeButton?.size?.medium?.width,
                    paddingLeft: themeButton?.size?.medium?.padding_left,
                    paddingRight: themeButton?.size?.medium?.padding_right,
                    paddingTop: themeButton?.size?.medium?.padding_top,
                    paddingBottom: themeButton?.size?.medium?.padding_bottom,
                    marginLeft: themeButton?.size?.medium?.margin_left,
                    marginRight: themeButton?.size?.medium?.margin_right,
                    marginTop: themeButton?.size?.medium?.margin_top,
                    marginBottom: themeButton?.size?.medium?.margin_bottom,
                    fontWeight: themeButton?.size?.medium?.font_weight,
                    fontSize: themeButton?.size?.medium?.font_size,
                    fontFamily: themeButton?.size?.medium?.font_family,
                    lineHeight: themeButton?.size?.medium?.line_height,
                  }),
                  ...(ownerState.variant === 'auth' && {
                    color: themeButton?.variant?.auth?.active_font_color ?? '#000000',
                    background: themeButton?.variant?.auth?.active_background_color || '#ffffff',
                    borderColor: themeButton?.variant?.auth.active_border_color ?? '#E1E1E1',
                    borderRadius: themeButton?.size?.large?.border_radius ?? '#000000',
                    borderWidth: themeButton?.size?.large?.border_thickness ?? '1px',
                    borderStyle: themeButton?.size?.large?.border_style ?? 'solid',
                  }),
                }),
              },
            },
            JoyInput: {
              styleOverrides: {
                root: {
                  height: themeInput?.height,
                  borderStyle: themeInput?.border_style,
                  borderColor: themeInput?.border_color,
                  borderWidth: themeInput?.border_width,
                  backgroundColor: themeInput?.background_color,
                  paddingLeft: themeInput?.padding_left,
                  paddingRight: themeInput?.padding_right,
                  paddingTop: themeInput?.padding_top,
                  paddingBottom: themeInput?.padding_bottom,
                  marginLeft: themeInput?.margin_left,
                  marginRight: themeInput?.margin_right,
                  marginTop: themeInput?.margin_top,
                  marginBottom: themeInput?.margin_bottom,
                  '--Input-radius': themeInput?.border_radius,
                  '--Input-decoratorChildHeight': `40px`,
                },
              },
            },
            JoySelect: {
              styleOverrides: {
                root: ({ ownerState }) => ({
                  ...(ownerState.size === 'md' && {
                    height: '56px',
                  }),
                }),
              },
            },
          },
          typography: {
            footer: {
              fontSize: 'var(--smt-fontSize-xs)',
              lineHeight: 'var(--smt-lineHeight-xs)',
              marginBottom: '3px',
            },
          },
        });

        // Configure Material Theme

        const materialTheme = materialExtendTheme({
          components: {
            MuiButtonBase: {
              defaultProps: {
                disableRipple: true, // No more ripple, on the whole application
              },
            },
            MuiButton: {
              styleOverrides: {
                root: ({ ownerState }) => ({
                  ...(ownerState.variant === 'contained' &&
                    ownerState.color === 'primary' && {
                      backgroundColor:
                        themeButton?.variant?.primary.active_background_color ?? '#00000',
                      color: themeButton?.variant?.primary.active_font_color ?? '#00000',
                    }),
                  ...(ownerState.size === 'large' && {
                    height: '52px',
                  }),
                  borderRadius: themeButton?.size?.large?.border_radius ?? '#00000',
                  padding: '4px 12px',
                }),
              },
            },
            MuiInput: {
              styleOverrides: {
                root: {
                  backgroundColor: themeInput?.background_color,
                  borderRadius: themeInput?.border_radius,
                  borderColor: themeInput?.border_color,
                },
                underline: {
                  '&:before': {
                    borderBottom: 'none',
                  },
                  '&:after': {
                    borderBottom: 'none',
                  },
                },
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                root: {
                  backgroundColor: themeInput?.background_color,
                  borderRadius: themeInput?.border_radius,
                  borderColor: themeInput?.border_color,
                },
              },
            },
          },
        });

        // Palette;
        if (themePalette?.background && themePalette.background.length > 0) {
          materialTheme.colorSchemes.light.palette.background.default = themePalette?.background;
        }
        if (themePalette?.info && themePalette.info.length > 0) {
          materialTheme.colorSchemes.light.palette.info.main = themePalette?.info;
        }
        if (themePalette?.success && themePalette.success.length > 0) {
          materialTheme.colorSchemes.light.palette.success.main = themePalette?.success;
        }
        if (themePalette?.error && themePalette.error.length > 0) {
          materialTheme.colorSchemes.light.palette.error.main = themePalette?.error;
        }
        if (themePalette?.warning && themePalette.warning.length > 0) {
          materialTheme.colorSchemes.light.palette.warning.main = themePalette?.warning;
        }

        this.materialTheme = materialTheme;

        return Promise.resolve(config);
      })
      .then((config) => {
        this.config = config;
        const resources: Resource = {};
        const {
          locales = {},
          disable_built_in_language: disableBuiltInLanguage,
          supported_locales: supportedLocales,
          default_language: defaultLanguage,
        } = config;
        if (!disableBuiltInLanguage) {
          resources.en = { translation: LocaleEn };
        }

        let defaultLang: string | null = null;
        if (typeof defaultLanguage === 'string') {
          defaultLang = defaultLanguage;
        } else if (Array.isArray(defaultLanguage)) {
          [defaultLang] = defaultLanguage;
        }
        if (defaultLang && supportedLocales?.indexOf(defaultLang) === -1) {
          // eslint-disable-next-line no-param-reassign
          config.default_language = supportedLocales;
        }
        return Promise.all(
          // Map over each available languages in the config's locale section (generally set in the global config)
          Object.keys(locales).map(async (localeLngKey) => {
            // Don't fetch unsupported languages (but always fetch english)
            if (supportedLocales?.indexOf(localeLngKey) === -1 && localeLngKey !== 'en') {
              return Promise.resolve();
            }

            const { external, overrides } = locales[localeLngKey];

            // Fetch external translation files
            if (external?.length > 0) {
              Log.info('[Languages] Fetching external', external);
              try {
                const result = await fetch(external);
                const externalJson = await result.json();
                const mergedTranslations = deepMerge(externalJson, overrides);
                resources[localeLngKey] = { translation: mergedTranslations };
                // Handle en special case
                if (localeLngKey === 'en') {
                  // Use en as the key-only fallback
                  resources.fb = resources.en;
                  // If en isn't supported, delete it
                  if (supportedLocales && !supportedLocales.includes('en')) {
                    delete resources.en;
                  }
                }
              } catch {
                return {};
              }
            }
            return Promise.resolve();
          })
        ).then(() => {
          const fallbackLng = [...(this.config?.default_language ?? []), 'fb'];
          const options: InitOptions = {
            debug: true,
            fallbackLng,
            detection: {
              order: ['localStorage', 'navigator', 'querystring', 'cookie', 'htmlTag'],
            },
            resources,
          };
          i18n.use(LanguageDetector).use(initReactI18next).init(options);
          this.setState({ config: this.config });
        });
      })
      .catch((error) => {
        Log.error('App load error:', error);
      });
  }

  render(): JSX.Element {
    const { config } = this.state;
    const { children } = this.props;
    return (
      <MaterialCssVarsProvider
        theme={{ [MATERIAL_THEME_ID]: this.materialTheme }}
        defaultMode="light"
      >
        <JoyCssVarsProvider theme={this.joyTheme} defaultColorScheme="light" defaultMode="light">
          <CssBaseline enableColorScheme />
          <AlertProvider>{config && children?.(config)}</AlertProvider>
        </JoyCssVarsProvider>
      </MaterialCssVarsProvider>
    );
  }
}

// in your theme or index file
declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
    secondary: true;
  }

  interface Palette {
    // make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
    secondary: PaletteRange;
  }

  interface TypographySystemOverrides {
    footer: true;
  }
}

declare module '@mui/joy/Button' {
  interface ButtonPropsVariantOverrides {
    auth: true;
  }
}
