import React from 'react';
import { AuthProvider, FormattedToken } from '../../types/common';
import {
  Config,
  FormItem,
  InputAdditionalItem,
  LanguagePolicies,
  RawFormItem,
} from '../../types/config';
import { NotificationType } from '../../types/vlabs-user';
import BaseModel from '../../util/model';
import { CountryInfo } from '../../util/countries';

// View pages
export enum ClaimPage {
  ageGateLanding = 0,
  ageGateError,
  landing,
  capture,
  link,
  expired,
  otp,
  phone,
  signChallenge,
  prove,
}

export interface StateModel {
  authProvider: AuthProvider | null;
  config: Config;
  hash: string | null;
  index: ClaimPage;
  notificationType: NotificationType | null;
  showMobileOnly: boolean;
  inputToken: string;
  formattedToken: FormattedToken | null;
  avatarURL: string | null;
  inputName: string;
  socialName: string | null;
  inputCustom: InputAdditionalItem[] | null;
  inputAdditional: string | null;
  acceptedPrimaryConsent: boolean;
  acceptedMarketingConsent: boolean;
  isConsentPopupContinueEnabled: boolean;
  isAuthComplete: boolean;
  dateOfBirth: string | null;
  language: string;
  languagePolicies: LanguagePolicies | null;
  defaultPhoneRegion: CountryInfo | null;
  continuationSessionId: string | null;
  continuationDraftId: string | null;
  showProveFallback: boolean;
  formItems: FormItem[];
  /** Form items collected prior to collecting landing page form items, e.g. from pre-auth iframe */
  preFormItems: RawFormItem[];
}

export default class Model extends BaseModel implements StateModel {
  constructor(
    component: React.Component,
    inputCustom: InputAdditionalItem[] | null,
    isConsentPopupContinueEnabled: boolean,
    showMobileOnly: boolean
  ) {
    super(component, {
      authProvider: null,
      config: null,
      hash: null,
      index: -1,
      notificationType: null,
      showMobileOnly: showMobileOnly ?? false,
      inputToken: '',
      formattedToken: null,
      avatarURL: null,
      inputName: '',
      socialName: null,
      inputCustom,
      inputAdditional: null,
      acceptedPrimaryConsent: false,
      acceptedMarketingConsent: false,
      isConsentPopupContinueEnabled,
      isAuthComplete: false,
      dateOfBirth: null,
      language: 'en',
      languagePolicies: null,
      defaultPhoneRegion: null,
      continuationSessionId: null,
      continuationDraftId: null,
      showProveFallback: false,
      formItems: [],
      preFormItems: [],
    });
  }

  get hash(): string {
    const { hash } = this.value;
    return hash;
  }

  set hash(value: string) {
    this.value = { hash: value };
  }

  get authProvider(): AuthProvider | null {
    const { authProvider } = this.value;
    return authProvider;
  }

  set authProvider(value: AuthProvider | null) {
    this.value = { authProvider: value };
  }

  get index(): ClaimPage {
    const { index } = this.value;
    return index;
  }

  set index(value: ClaimPage) {
    this.value = { index: value };
  }

  get inputToken(): string {
    const { inputToken } = this.value;
    return inputToken;
  }

  set inputToken(value: string) {
    this.value = { inputToken: value };
  }

  get formattedToken(): FormattedToken | null {
    const { formattedToken } = this.value;
    return formattedToken;
  }

  set formattedToken(value: FormattedToken | null) {
    this.value = { formattedToken: value };
  }

  get notificationType(): NotificationType {
    const { notificationType } = this.value;
    return notificationType;
  }

  set notificationType(value: NotificationType) {
    this.value = { notificationType: value };
  }

  get config(): Config {
    const { config } = this.value;
    return config;
  }

  set config(value: Config) {
    this.value = { config: value };
  }

  get showMobileOnly(): boolean {
    const { showMobileOnly } = this.value;
    return showMobileOnly;
  }

  set showMobileOnly(value: boolean) {
    this.value = { showMobileOnly: value };
  }

  get avatarURL(): string | null {
    const { avatarURL } = this.value;
    return avatarURL;
  }

  set avatarURL(value: string | null) {
    this.value = { avatarURL: value };
  }

  get inputName(): string {
    const { inputName } = this.value;
    return inputName;
  }

  set inputName(value: string) {
    this.value = { inputName: value };
  }

  get socialName(): string | null {
    const { socialName } = this.value;
    return socialName;
  }

  set socialName(value: string | null) {
    this.value = { socialName: value };
  }

  get inputCustom(): InputAdditionalItem[] | null {
    const { customInput } = this.value;
    return customInput;
  }

  set inputCustom(value: InputAdditionalItem[] | null) {
    this.value = { customInput: value };
  }

  get inputAdditional(): string | null {
    const { inputAdditional } = this.value;
    return inputAdditional;
  }

  set inputAdditional(value: string | null) {
    this.value = { inputAdditional: value };
  }

  get acceptedPrimaryConsent(): boolean {
    const { acceptedPrimaryConsent } = this.value;
    return acceptedPrimaryConsent;
  }

  set acceptedPrimaryConsent(value: boolean) {
    this.value = { acceptedPrimaryConsent: value };
  }

  get acceptedMarketingConsent(): boolean {
    const { acceptedMarketingConsent } = this.value;
    return acceptedMarketingConsent;
  }

  set acceptedMarketingConsent(value: boolean) {
    this.value = { acceptedMarketingConsent: value };
  }

  get isConsentPopupContinueEnabled(): boolean {
    const { isConsentPopupContinueEnabled } = this.value;
    return isConsentPopupContinueEnabled;
  }

  set isConsentPopupContinueEnabled(value: boolean) {
    this.value = { isConsentPopupContinueEnabled: value };
  }

  get isAuthComplete(): boolean {
    const { isAuthComplete } = this.value;
    return isAuthComplete;
  }

  set isAuthComplete(value: boolean) {
    this.value = { isAuthComplete: value };
  }

  get dateOfBirth(): string | null {
    const { dateOfBirth } = this.value;
    return dateOfBirth;
  }

  set dateOfBirth(value: string | null) {
    this.value = { dateOfBirth: value };
  }

  get language(): string {
    const { language } = this.value;
    return language;
  }

  set language(value: string) {
    this.value = { language: value };
  }

  get languagePolicies(): LanguagePolicies | null {
    const { languagePolicies } = this.value;
    return languagePolicies;
  }

  set languagePolicies(value: LanguagePolicies | null) {
    this.value = { languagePolicies: value };
  }

  get continuationSessionId(): string | null {
    const { continuationSessionId } = this.value;
    return continuationSessionId;
  }

  set continuationSessionId(value: string | null) {
    this.value = { continuationSessionId: value };
  }

  get continuationDraftId(): string | null {
    const { continuationDraftId } = this.value;
    return continuationDraftId;
  }

  set continuationDraftId(value: string | null) {
    this.value = { continuationDraftId: value };
  }

  get defaultPhoneRegion(): CountryInfo | null {
    const { defaultPhoneRegion } = this.value;
    return defaultPhoneRegion;
  }

  set defaultPhoneRegion(value: CountryInfo | null) {
    this.value = { defaultPhoneRegion: value };
  }

  get showProveFallback(): boolean {
    const { showProveFallback } = this.value;
    return showProveFallback;
  }

  set showProveFallback(value: boolean) {
    this.value = { showProveFallback: value };
  }

  get formItems(): FormItem[] {
    const { formItems } = this.value;
    return formItems;
  }

  set formItems(value: FormItem[]) {
    this.value = { formItems: value };
  }

  get preFormItems(): RawFormItem[] {
    const { preFormItems } = this.value;
    return preFormItems;
  }

  set preFormItems(value: RawFormItem[]) {
    this.value = { preFormItems: value };
  }
}
