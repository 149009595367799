import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Link, Stack, Typography } from '@mui/joy';
import { TFunction } from 'i18next';
import ViewBase from '../../views/view-base';

interface ViewProps {
  t: TFunction;
  instantLinkAuthURL: string | null;
  onResendClick: VoidFunction;
}

function View(props: ViewProps): JSX.Element {
  const { t, instantLinkAuthURL, onResendClick } = props;

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    const initialCooldownTimeout = setTimeout(() => {
      setIsButtonEnabled(true);
    }, 20000); // initial second cooldown

    return () => {
      clearTimeout(initialCooldownTimeout);
    };
  }, []);

  useEffect(() => {
    const cooldownTimeout = setTimeout(() => {
      setIsButtonEnabled(true);
    }, 20000); // seconds in milliseconds

    return () => {
      clearTimeout(cooldownTimeout);
    };
  }, [isButtonEnabled]);

  const handleClick = (): void => {
    setIsButtonEnabled(false);
    onResendClick();
  };

  return (
    <ViewBase headerText={t('claim.prove.link.title')} prefix="prove">
      <Typography textAlign="center">{t('claim.prove.link.sub_title')}</Typography>
      <Button
        variant="plain"
        sx={{ mt: '40px' }}
        href=""
        onClick={handleClick}
        disabled={!isButtonEnabled}
      >
        {t('claim.prove.link.no_link')}
      </Button>
      {/* REMOVE */}
      {instantLinkAuthURL && (
        <Stack
          alignItems="center"
          spacing={2}
          px="31px"
          sx={{ position: 'absolute', bottom: '42px' }}
        >
          <Typography>FAKE SMS</Typography>
          <Link sx={{ wordBreak: 'break-word' }} href={instantLinkAuthURL}>
            {instantLinkAuthURL}
          </Link>
        </Stack>
      )}
      {/* REMOVE */}
    </ViewBase>
  );
}

export default withTranslation()(View);
