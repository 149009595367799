import { RouteComponentProps } from 'react-router-dom';
import { TFunction } from 'i18next';
import { Config } from './config';

export type Translator = (t: TFunction) => string;

export type AuthProvider = 'facebook' | 'google' | 'outside' | 'token' | 'walletConnect' | 'prove';

interface MatchProps {
  email?: string;
  ukey?: string;
}

export interface PageProps extends RouteComponentProps<MatchProps> {
  config: Config;
}

export type TokenType = 'email' | 'phone';

export type FormattedToken = {
  type: TokenType;
  value: string;
};

export type AppPage =
  | 'claim'
  | 'claim-multi'
  | 'login'
  | 'login-multi'
  | 'unsubscribe'
  | 'unsubscribe-multi'
  | 'theme';

export enum LocalePrefix {
  claim = 'claim',
  login = 'login',
  unsubscribe = 'unsubscribe',
}
