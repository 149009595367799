import Model from './model';
import { Config, ConsentType, RawFormItem, Token } from '../../../types/config';
import { AuthProvider } from '../../../types/common';

export default class Presenter {
  model: Model;

  facebookAppId: string;

  googleAppId: string;

  outsideAppId: string;

  walletConnectId: string | undefined;

  oauthOnly: boolean;

  config: Config;

  token: Token;

  selectedAuthProvider: AuthProvider | null = null;

  previousIframeScroll?: number;

  iframeScrollUpStart?: number;

  showProveFallback: boolean;

  onNext: (authProvider: AuthProvider) => void;

  onConsentChange: (type: ConsentType, consent: boolean) => void;

  onPreFormItemsChange: (items: [RawFormItem]) => void;

  constructor(
    model: Model,
    config: Config,
    showProveFallback: boolean,
    onNext: (authProvider: AuthProvider) => void,
    onConsentChange: (type: ConsentType, consent: boolean) => void,
    onPreFormItemsChange: (items: [RawFormItem]) => void
  ) {
    this.facebookAppId = config.facebook_app_id;
    this.googleAppId = config.google_app_id;
    this.outsideAppId = config.outside_app_id;
    this.walletConnectId = config.wallet_connect?.project_id;
    this.oauthOnly = config.oauth_only;
    this.config = config;
    this.token = config.token;
    this.onNext = onNext;
    this.onConsentChange = onConsentChange;
    this.model = model;
    this.showProveFallback = showProveFallback;
    this.onPreFormItemsChange = onPreFormItemsChange;
  }

  async onAttach(): Promise<void> {
    const { allow_phone: allowPhone, allow_email: allowEmail } = this.token;
    this.model.oauthOnly = !!this.oauthOnly;
    this.model.hideAuth = this.config.hide_auth;

    // check if Prove is enabled and were not showing the fallback
    const proveEnabled = this.config.prove?.enabled ?? false;

    if (proveEnabled) {
      if (this.showProveFallback) {
        this.model.showProve = false;
        this.model.allowPhone = allowPhone;
        this.model.allowEmail = allowEmail;
        this.model.showGoogle = !!this.googleAppId;
        this.model.showFacebook = !!this.facebookAppId;
        this.model.showOutsideOauth = !!this.outsideAppId;
        this.model.showWalletConnect = !!this.walletConnectId;
      } else {
        this.model.showProve = true;
        this.model.allowPhone = false;
        this.model.allowEmail = false;
        this.model.showGoogle = false;
        this.model.showFacebook = false;
        this.model.showOutsideOauth = false;
        this.model.showWalletConnect = false;
      }
    } else {
      this.model.allowPhone = allowPhone;
      this.model.allowEmail = allowEmail;
      this.model.showGoogle = !!this.googleAppId;
      this.model.showFacebook = !!this.facebookAppId;
      this.model.showOutsideOauth = !!this.outsideAppId;
      this.model.showWalletConnect = !!this.walletConnectId;
    }

    const { marketing: marketingConsent } = this.config.claim.consent ?? {};

    this.model.showMarketingConsent = marketingConsent?.enabled ?? false;
    this.model.marketingConsentTerms = marketingConsent?.terms ?? '';
    window.addEventListener('message', this.onReceiveMessage);
  }

  onDetach(): void {
    window.removeEventListener('message', this.onReceiveMessage);
  }

  onAuthProviderClick(provider: AuthProvider): void {
    this.selectedAuthProvider = provider;
    if (provider !== 'token') {
      this.openConsentPopup(provider);
    } else {
      this.onNext(provider);
    }
  }

  openConsentPopup(authProvider: AuthProvider): void {
    this.selectedAuthProvider = authProvider;
    this.model.isConsentPopupOpen = true;
  }

  closeConsentPopup(): void {
    this.model.isConsentPopupOpen = false;
  }

  onConsentPopupContinue(): void {
    this.onNext(this.selectedAuthProvider!);
  }

  onCardExpandClick(): void {
    this.model.collapseCard = false;
    this.iframeScrollUpStart = undefined;
  }

  onReceiveMessage = (event: MessageEvent): void => {
    const data = event.data as Record<string, any> | undefined;

    if (data === undefined) return;

    if (data.event === 'clu-auth-visible') {
      this.model.hideAuth = !data.value;
    }

    if (data.event === 'clu-form-items') {
      const items = data.value;
      this.onPreFormItemsChange(items);
    }

    if (data.event === 'iframe-scroll') {
      const previous = this.previousIframeScroll;
      this.previousIframeScroll = data.offsetY;

      if (previous !== undefined && this.previousIframeScroll !== undefined) {
        if (this.previousIframeScroll < previous) {
          if (this.iframeScrollUpStart === undefined) {
            this.iframeScrollUpStart = previous;
          } else if (
            this.previousIframeScroll + 100 < this.iframeScrollUpStart ||
            this.previousIframeScroll <= 0
          ) {
            this.model.collapseCard = false;
            this.iframeScrollUpStart = undefined;
          }
        } else if (this.previousIframeScroll > 100) {
          this.model.collapseCard = true;
          this.iframeScrollUpStart = undefined;
        }
      }
    }
  };
}
