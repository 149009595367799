import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import Model from './model';
import { validateEmail, validatePhone } from '../../../util/validation';
import {
  Config,
  ConsentType,
  InputAdditionalItem,
  FormItem,
  LanguagePolicies,
  Token,
  RawFormItem,
} from '../../../types/config';
import Countries, { CountryInfo } from '../../../util/countries';
import CountryPicker from '../../country-select';
import { NotificationType, VLabsParams } from '../../../types/vlabs-user';
import Log from '../../../util/log';
import ErrorPopup from '../../error';
import { AuthProvider, FormattedToken } from '../../../types/common';

export default class Presenter {
  advertiserName: string;

  age: any;

  config: Config;

  dateOfBirth: string | null;

  language: string;

  model: Model;

  selectedCountry: CountryInfo | null = null;

  token: Token;

  acceptedPrimaryConsent: boolean;

  acceptedMarketingConsent: boolean;

  inputToken: string;

  formattedToken: FormattedToken | null = null;

  inputName: string;

  inputCustom: InputAdditionalItem[] | null;

  inputAdditional: string | null;

  isAuthComplete: boolean;

  onConsentChange: (type: ConsentType, consent: boolean) => void;

  onRegister: (notificationType: string, formattedToken?: FormattedToken) => void;

  onBack: VoidFunction;

  languagePolicies: LanguagePolicies;

  formItems: FormItem[];

  onFormItemChange: (formItem: FormItem, value: any) => void;

  preFormItems: RawFormItem[];

  supportedCountryCodes: CountryInfo[] = [];

  constructor(
    model: Model,
    config: Config,
    inputToken: string,
    inputName: string,
    inputCustom: InputAdditionalItem[] | null,
    inputAdditional: string | null,
    acceptedPrimaryConsent: boolean,
    acceptedMarketingConsent: boolean,
    language: string,
    dateOfBirth: string | null,
    isAuthComplete: boolean,
    onConsentChange: (type: ConsentType, consent: boolean) => void,
    onBack: VoidFunction,
    onRegister: (notificationType: string, formattedToken?: FormattedToken) => void,
    languagePolicies: LanguagePolicies,
    formItems: FormItem[],
    preFormItems: RawFormItem[],
    onFormItemChange: (formItem: FormItem, value: any) => void
  ) {
    this.model = model;
    this.advertiserName = config.advertiser_name ?? '';
    this.config = config;
    this.age = config.age;
    this.token = config.token;
    this.inputToken = inputToken;
    this.inputName = inputName;
    this.inputCustom = inputCustom;
    this.inputAdditional = inputAdditional;
    this.acceptedPrimaryConsent = acceptedPrimaryConsent;
    this.acceptedMarketingConsent = acceptedMarketingConsent;
    this.language = language;
    this.dateOfBirth = dateOfBirth;
    this.isAuthComplete = isAuthComplete;
    this.onConsentChange = onConsentChange;
    this.onBack = onBack;
    this.onRegister = onRegister;
    this.languagePolicies = languagePolicies;
    this.formItems = formItems;
    this.preFormItems = preFormItems;
    this.onFormItemChange = onFormItemChange;
  }

  async onAttach(): Promise<void> {
    const { allow_phone: allowPhone, allow_email: allowEmail, regions } = this.token;
    const {
      consent,
      capture_additional: captureAdditional,
      capture_name: captureName,
      name_type: nameType,
    } = this.config.claim;

    this.model.allowPhone = allowPhone;
    this.model.allowEmail = allowEmail;
    this.model.captureName = captureName;
    this.model.nameType = nameType ?? null;
    this.model.captureAdditional = captureAdditional;
    this.model.advertiserName = this.advertiserName;

    const { marketing: marketingConsent } = consent ?? {};

    this.model.showMarketingConsent = marketingConsent?.enabled ?? false;
    this.model.marketingConsentTerms = marketingConsent?.terms ?? '';

    if (allowPhone) {
      this.supportedCountryCodes = await Countries.getCountryInfoForRegions(regions ?? []);
      await this.updateDefaultCountryCode(regions);
    }
  }

  async updateDefaultCountryCode(regions: string[] | null): Promise<void> {
    // set the default country immediately (other look ups are async)
    this.selectedCountry = Countries.getDefault();
    this.model.countryCode = this.selectedCountry?.phonePrefix ?? null;

    // disable picker if there is only one choice
    if (regions?.length === 1) {
      this.model.disableCountryPicker = true;
    }

    // if we have a continuation token, show the country code
    if (this.inputToken) {
      this.onTokenChange(this.inputToken);
    }

    // find a recommended country, if any, to replace the default
    const recommendedCountry = await Countries.getRecommendedCountryRestrictedByRegion(regions);
    console.log('recommendedCountry', recommendedCountry);

    this.selectedCountry = recommendedCountry;
    this.model.countryCode = this.selectedCountry?.phonePrefix ?? null;
  }

  onTokenChange(value: string): void {
    const { allow_phone: allowPhone } = this.token;
    if (allowPhone && validatePhone(value) && value.indexOf('+') !== 0) {
      this.model.showCountryCode = true;
    } else {
      this.model.showCountryCode = false;
    }
  }

  onCountryCodeClick(): void {
    const { regions } = this.token;
    CountryPicker.show(this.selectedCountry, regions ?? [], null, (selected: CountryInfo) => {
      if (selected) {
        this.selectedCountry = selected;
        this.model.countryCode = this.selectedCountry.phonePrefix;
      }
    });
  }

  validateForm(provider: AuthProvider): boolean {
    this.inputToken = this.inputToken?.trim();
    const { allow_phone: allowPhone, allow_email: allowEmail } = this.token;

    const { capture_name: captureName, capture_additional: captureAdditional } = this.config.claim;

    let hasErrors = false;

    if (provider === 'token') {
      if (allowPhone && allowEmail) {
        if (!validateEmail(this.inputToken) && !validatePhone(this.inputToken)) {
          this.model.errorToken = 'claim.landing.error_invalid_email_phone_number';
          hasErrors = true;
        } else this.model.errorToken = null;
      } else if (allowEmail) {
        if (!validateEmail(this.inputToken)) {
          this.model.errorToken = 'claim.landing.error_invalid_email';
          hasErrors = true;
        } else this.model.errorToken = null;
      } else if (allowPhone) {
        if (!validatePhone(this.inputToken)) {
          this.model.errorToken = 'claim.landing.error_invalid_phone_number';
          hasErrors = true;
        } else this.model.errorToken = null;
      }

      if (allowEmail && validateEmail(this.inputToken)) {
        this.formattedToken = { value: this.inputToken, type: 'email' };
      } else if (allowPhone && validatePhone(this.inputToken)) {
        try {
          const phoneUtil = PhoneNumberUtil.getInstance();
          if (!this.selectedCountry?.code) {
            this.model.errorToken = 'claim.landing.error_invalid_region';
          } else {
            const phoneNumber = phoneUtil.parse(this.inputToken, this.selectedCountry.code);
            const token = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164).trim();
            this.formattedToken = { value: token, type: 'phone' };
            if (!phoneUtil.isValidNumber(phoneNumber)) {
              this.model.errorToken = 'claim.landing.error_invalid_phone_number';
              hasErrors = true;
            } else if (
              this.supportedCountryCodes.length > 0 &&
              !this.supportedCountryCodes.find((countryInfo) =>
                token.startsWith(countryInfo.phonePrefix)
              )
            ) {
              this.model.errorToken = 'claim.landing.error_invalid_region';
              hasErrors = true;
            } else if (token.length > 0) {
              this.inputToken = token;
              this.model.errorToken = null;
            }
          }
        } catch (error) {
          Log.error(error);
          this.model.errorToken = 'claim.landing.error_invalid_phone_number';
          hasErrors = true;
        }
      }
    } else {
      this.model.errorToken = null;
      this.model.errorName = null;
    }

    if (captureAdditional && captureAdditional.length > 0) {
      if (!this.inputAdditional) {
        hasErrors = true;
        this.model.errorAdditional = 'claim.landing.additional.error_select';
      } else this.model.errorAdditional = null;
    }

    // validate form items
    this.formItems.forEach((item) => {
      if (item.type === 'checkbox') {
        const hasError = item.required && item.required && !item.value;
        const updatedItem = item;
        updatedItem.error = hasError;
        this.onFormItemChange(updatedItem, updatedItem.value);
      } else if (item.type === 'dropdown') {
        const hasError = !!item.required && !item.value;
        const updatedItem = item;
        updatedItem.error = hasError;
        this.onFormItemChange(updatedItem, updatedItem.value);
      }
    });

    if (this.formItems.find((item) => item.error)) {
      hasErrors = true;
    }

    this.inputCustom?.forEach((input) => {
      if (input.required && !input.value) {
        hasErrors = true;
        // eslint-disable-next-line no-param-reassign
        input.error = input.required && 'required';
      } else {
        // eslint-disable-next-line no-param-reassign
        input.error = '';
      }
    });

    if (provider === 'token' && captureName) {
      if (this.inputName?.length === 0) {
        this.model.errorName = 'claim.landing.error_invalid_name';
        hasErrors = true;
      } else this.model.errorName = null;
    }

    if (!this.isAuthComplete) {
      if (!this.acceptedPrimaryConsent) {
        this.model.errorPrimaryConsent = 'claim.landing.error_consent';
        hasErrors = true;
      } else {
        this.model.errorPrimaryConsent = null;
      }
    }

    return hasErrors;
  }

  onNextClick(provider: AuthProvider): void {
    this.inputToken = this.inputToken?.trim();

    const { delay: errorDelay, redirect_on_go: redirectOnGoError } = this.config.error;

    // Validate form
    const hasErrors = this.validateForm(provider);

    console.log(
      `
      Form errored            : ${hasErrors}
        errorPrimaryConsent   : ${this.model.errorPrimaryConsent}
        errorName             : ${this.model.errorName}
        errorEmail            : ${this.model.errorEmail}
        errorPhone            : ${this.model.errorPhone}
        errorToken            : ${this.model.errorToken}
        errorAdditional       : ${this.model.errorAdditional}
    `
    );

    // Build vlabs params
    if (!hasErrors) {
      this.model.errorPhone = null;
      this.model.errorEmail = null;
      this.model.errorName = null;
      this.model.loading = true;

      const params: VLabsParams = { user: this.formattedToken?.value };

      if (this.inputName?.length > 0) {
        params.name = this.inputName;
      }

      if (this.inputAdditional) {
        params.select = this.inputAdditional;
      }

      params.language = this.language;

      // Consent Framework
      if (this.acceptedPrimaryConsent) {
        // Build accepted policies
        const accepted = this.languagePolicies;

        // Remove marketing if enabled but not accepted
        if (
          this.config.claim.consent.marketing?.enabled === true &&
          !this.acceptedMarketingConsent
        ) {
          delete accepted.marketing;
        }
        params.policies_accepted = accepted;
      }

      if (this.dateOfBirth) {
        params.birthday = this.dateOfBirth;
      }

      this.inputCustom?.forEach((input, index) => {
        params[`input_${index + 1}`] = input.value;
      });

      // get config form items
      params.form_items = this.formItems.map((item) => ({
        id: item.id,
        value: item.value ?? null,
      }));

      // map form item shape to legacy param shape
      const preFormItemsMapped = Object.fromEntries(
        this.preFormItems.map((obj) => [obj.id, obj.value])
      );

      // add iframe form items as params (mimicking url search param behavior)
      params.additionalParams = preFormItemsMapped;

      if (provider === 'token') {
        // Register the user (token flow captures input before registration).
        Log.warn(`[Debug] VlabsUser.go('/signup', params: ${JSON.stringify(params)}`);
        VlabsUser.go(
          '/signup',
          params,
          (data, notificationType: NotificationType) => {
            this.onRegister?.(notificationType, this.formattedToken!);
          },
          (error) => {
            Log.error('[Debug] .go(/signup) error:', error);
            if (error.code === 94) {
              // if error 'token_expired', push user back to start
              ErrorPopup.show(error, () => window.location.reload());
            } else {
              ErrorPopup.show(error, () => {}, redirectOnGoError && errorDelay);
            }
          }
        ).finally((): void => {
          this.model.loading = false;
        });
      } else {
        // Update the user (OAuth & WalletConnect capture inputs after registration).
        Log.warn(`[Debug] VlabsUser.go('/update', params: ${JSON.stringify(params)}`);
        VlabsUser.go(
          '/update',
          params,
          (_, notificationType: NotificationType) => {
            this.onRegister?.(notificationType);
          },
          (error) => {
            ErrorPopup.show(error, () => {}, redirectOnGoError && errorDelay);
            Log.error(error);
          }
        ).finally((): void => {
          this.model.loading = false;
        });
      }
    }
  }

  onBackClick(): void {
    this.onBack();
  }
}
