import React from 'react';
import { Grid, Select, Option } from '@mui/joy';
import i18next, { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { FormItem } from '../../types/config';
import Log from '../../util/log';
import FormElement from '../../components-v2/form/form-element';
import Checkbox from '../../components-v2/checkbox';

interface AdditionalProps {
  t: TFunction;
  formItems: FormItem[];
  onItemChange: (item: FormItem, value: string | boolean | null) => void;
}

function AdditionalForm(props: AdditionalProps): JSX.Element | null {
  const { t, formItems, onItemChange } = props;

  // ensure we recognize the type
  if (formItems?.find((item) => item.type !== 'checkbox' && item.type !== 'dropdown')) {
    Log.error('Unrecognized from type');
    return null;
  }

  return (
    <>
      {formItems.map((item) => {
        // parse strings
        const localePath = `claim.form_item.${item.id}`;
        const label = i18next.exists(`${localePath}.label`) ? t(`${localePath}.label`) : undefined;
        const placeholder = i18next.exists(`${localePath}.placeholder`)
          ? t(`${localePath}.placeholder`)
          : undefined;
        const helper = i18next.exists(`${localePath}.helper`)
          ? t(`${localePath}.helper`)
          : undefined;

        return (
          <Grid xs={12} key={item.id}>
            <FormElement size="lg" required={item.required} label={label} helper={helper}>
              {item.type === 'checkbox' && (
                <Checkbox
                  size="lg"
                  label={t(`${localePath}.text`)}
                  checked={item.value}
                  onToggle={(value: boolean): void => {
                    onItemChange(item, value);
                  }}
                />
              )}
              {item.type === 'dropdown' && (
                <Select
                  size="lg"
                  value={item.value}
                  placeholder={placeholder}
                  onChange={(event: React.SyntheticEvent | null, newValue): void => {
                    onItemChange(item, newValue);
                  }}
                >
                  {item.options.map((option) => (
                    <Option key={option.name} value={option.value}>
                      {t(`${localePath}.option.${option.name}`)}
                    </Option>
                  ))}
                </Select>
              )}
            </FormElement>
          </Grid>
        );
      })}
    </>
  );
}

export default withTranslation()(AdditionalForm);

// eslint-disable-next-line no-lone-blocks
{
  /* <FormWrapper label={textForPath(`${page}.landing.input_label_email`)}>
  <Input
    id="email"
    name="email"
    placeholder={textForPath(`${page}.landing.input_placeholder_email`)}
    size="lg"
    required
    fullWidth
    autoComplete="email"
  />
</FormWrapper>; */
}
