import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { FormattedToken } from '../../types/common';
import { CountryInfo } from '../../util/countries';
import { validateEmail, validatePhone } from '../../util/validation';

export type TokenValidationErrorKey =
  | 'invalid_email_phone_number'
  | 'invalid_email'
  | 'invalid_phone_number'
  | 'invalid_region';

function formatAndValidate(
  allowEmail: boolean,
  allowPhone: boolean,
  inputToken: string,
  regions: string[],
  country: CountryInfo | null
): {
  formattedToken?: FormattedToken;
  errorKey: TokenValidationErrorKey | null;
} {
  let formattedToken: FormattedToken;
  let errorKey: TokenValidationErrorKey | undefined;

  // run through simple validation
  if (allowPhone && allowEmail) {
    if (!validateEmail(inputToken) && !validatePhone(inputToken)) {
      errorKey = 'invalid_email_phone_number';
      return { formattedToken: undefined, errorKey };
    }
  }

  if (allowEmail) {
    if (!validateEmail(inputToken)) {
      errorKey = 'invalid_email';
    } else {
      formattedToken = { value: inputToken, type: 'email' };
      return { formattedToken, errorKey: null };
    }
  }

  if (allowPhone) {
    if (!validatePhone(inputToken)) {
      errorKey = 'invalid_phone_number';
    } else {
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();

        if (!country?.code) {
          errorKey = 'invalid_region';
        } else {
          const phoneNumber = phoneUtil.parse(inputToken, country?.code);
          // check validity using google-libphonenumber
          if (!phoneUtil.isValidNumber(phoneNumber)) {
            errorKey = 'invalid_phone_number';
          } else if (regions && regions.length > 0) {
            // ensure the region is allow listed
            const countryCode = phoneNumber.getCountryCode();
            console.log('🚀 ~ countryCode:', countryCode);
            if (countryCode) {
              const countryRegionCode = phoneUtil.getRegionCodeForCountryCode(countryCode);
              console.log('🚀 ~ countryRegionCode:', countryRegionCode);

              // does the entered phone number's country code region match any of the config regions
              const isRegionAllowed = !regions.find((region) => region === countryRegionCode);
              if (!isRegionAllowed) {
                errorKey = 'invalid_region';
              }
            }
          }

          const formattedNumber = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164).trim();
          return { formattedToken: { value: formattedNumber, type: 'phone' }, errorKey: null };
        }
      } catch (error) {
        errorKey = 'invalid_phone_number';
      }
    }
  }

  return { formattedToken: undefined, errorKey: errorKey ?? null };
}

export default formatAndValidate;
