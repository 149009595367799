// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v05zN8SbTclIfoy4QNWQ{width:100%;display:flex;flex-direction:row;justify-content:start;align-items:flex-start}.xcqSI1EgyZyW2p4yZ9b0{width:100%;height:100%;position:absolute;top:0;bottom:0;left:0;right:0;color:#000}.qq3_wPEfg7xjRM6evwCT.wmB2oR7t93D87NR007nu{background-color:#fff}.qq3_wPEfg7xjRM6evwCT{position:relative;height:25px;width:25px;background-color:#fff;cursor:pointer;border-color:#cdcdcd;border-radius:0px;border-style:solid;border-width:1.5px;flex-shrink:0}._oJIbs1dggxEmy2xRDJt{padding-left:12px;font-size:12px;color:#333;flex-shrink:1;flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/components/checkbox-base/index.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,sBAAA,CAGF,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CACA,KAAA,CACA,QAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CAGF,2CACE,qBAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,qBAAA,CACA,cAAA,CACA,oBAAA,CACA,iBAAA,CACA,kBAAA,CACA,kBAAA,CACA,aAAA,CAGF,sBACE,iBAAA,CACA,cAAA,CACA,UAAA,CACA,aAAA,CACA,WAAA","sourcesContent":[".container {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: start;\n  align-items: flex-start;\n}\n\n.check {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  color: #000000;\n}\n\n.checkbox.checked {\n  background-color: #fff;\n}\n\n.checkbox {\n  position: relative;\n  height: 25px;\n  width: 25px;\n  background-color: #fff;\n  cursor: pointer;\n  border-color: #cdcdcd;\n  border-radius: 0px;\n  border-style: solid;\n  border-width: 1.5px;\n  flex-shrink: 0;\n}\n\n.text {\n  padding-left: 12px;\n  font-size: 12px;\n  color: #333;\n  flex-shrink: 1;\n  flex-grow: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `v05zN8SbTclIfoy4QNWQ`,
	"check": `xcqSI1EgyZyW2p4yZ9b0`,
	"checkbox": `qq3_wPEfg7xjRM6evwCT`,
	"checked": `wmB2oR7t93D87NR007nu`,
	"text": `_oJIbs1dggxEmy2xRDJt`
};
export default ___CSS_LOADER_EXPORT___;
