import clsx from 'clsx';
import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { withTranslation } from 'react-i18next';
import styles from './index.module.scss';
import Label from '../../components/label';
import Image from '../../components/image';

interface ViewProps {
  prefix: string;
  headerText?: string | null;
  headerStyles?: string | null;
  footerText?: string | null;
  footerStyles?: string | null;
  backgroundImageSrc?: string | null;
}

function View(props: React.PropsWithChildren<ViewProps>): JSX.Element {
  const {
    prefix,
    children,
    headerText,
    headerStyles,
    footerText,
    footerStyles,
    backgroundImageSrc,
  } = props;
  const isSmall = useMediaQuery('(max-width: 856px)');

  return (
    <div
      className={clsx(
        isSmall ? `${prefix}-base-container` : `${prefix}-base-container-anchored`,
        styles.container
      )}
    >
      {backgroundImageSrc && isSmall && (
        <div
          className={clsx(
            `base-background-image-container`,
            `${prefix}-base-background-image-container`,
            styles.imageContainer
          )}
        >
          <Image
            alt=""
            src={backgroundImageSrc}
            className={clsx(`${prefix}-base-background-image`, styles.image)}
          />
        </div>
      )}
      <div className={clsx(`${prefix}-base-inner-container`, styles.innerContainer)}>
        {headerText && (
          <Label className={clsx(styles.header, headerStyles)} text={headerText} variant="h5" />
        )}
        {children}
        <div className={clsx(`${prefix}-base-footer-space`, styles.footerSpace)} />
        {footerText && (
          <Label
            className={clsx(
              isSmall ? `${prefix}-base-footer` : `${prefix}-base-footer-anchored`,
              styles.footer,
              footerStyles
            )}
            text={footerText}
            variant="footer1"
          />
        )}
      </div>
    </div>
  );
}

View.defaultProps = {
  headerText: undefined,
  headerStyles: undefined,
  footerText: undefined,
  footerStyles: undefined,
  backgroundImageSrc: undefined,
};

export default withTranslation()(View);
