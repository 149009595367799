import React from 'react';
import { withTranslation } from 'react-i18next';
import i18next, { TFunction } from 'i18next';
import clsx from 'clsx';
import { FormItem } from '../../../types/config';
import CheckboxBase from '../../../components/checkbox-base';
import Select from '../../../components/select';
import Label from '../../../components/label';
import styles from './index.module.scss';

interface ViewProps {
  t: TFunction;
  formItems: FormItem[];
  onFormItemChange: (item: FormItem, value: any) => void;
}

function FromItemsView(props: ViewProps): JSX.Element {
  const { t, formItems, onFormItemChange } = props;

  return (
    <>
      {formItems.map((item) => {
        // parse locale strings
        const localePath = `claim.form_item.${item.id}`;
        const formLabel = i18next.exists(`${localePath}.label`)
          ? t(`${localePath}.label`)
          : undefined;
        const itemPlaceholder = i18next.exists(`${localePath}.placeholder`)
          ? t(`${localePath}.placeholder`)
          : undefined;
        const formHelper = i18next.exists(`${localePath}.helper`)
          ? t(`${localePath}.helper`)
          : undefined;

        return (
          <div className={clsx('form-item', styles.container)} key={item.id}>
            {formLabel && (
              <div className={clsx('form-item-container', styles.labelContainer)}>
                <Label
                  className={clsx('select-label', styles.label)}
                  text={formLabel ?? ''}
                  variant="subtitle2"
                />
                {item.required && (
                  <Label
                    className={clsx('form-item-required-label', styles.label, styles.labelRequired)}
                    text="*"
                    variant="subtitle2"
                    color="warning"
                  />
                )}
              </div>
            )}

            {item.type === 'checkbox' && (
              <CheckboxBase
                className={clsx('form-item-checkbox')}
                text={t(`${localePath}.text`)}
                checked={item.value ?? false}
                onCheckedToggle={(value: boolean): void => {
                  onFormItemChange(item, value);
                }}
                disabled={item.disabled}
              />
            )}
            {item.type === 'dropdown' && (
              <Select
                className={clsx('form-item-select')}
                placeholder={itemPlaceholder}
                items={item.options.map((option) => ({
                  value: option.value,
                  name: t(`${localePath}.option.${option.name}`),
                }))}
                onSelect={(value: string): void => onFormItemChange(item, value)}
                selected={item.value ?? null}
                disabled={item.disabled}
              />
            )}
            {formHelper && formHelper.length > 0 && (
              <Label
                className={clsx('form-item-helper-text', styles.helperText)}
                text={formHelper}
                variant="subtitle2"
                color={item.error ? 'warning' : undefined}
              />
            )}
            {!formHelper && item.error && (
              <Label
                className={clsx('form-item-helper-text', styles.helperText)}
                text="Required"
                variant="subtitle2"
                color={item.error ? 'warning' : undefined}
              />
            )}
          </div>
        );
      })}
    </>
  );
}

export default withTranslation()(FromItemsView);
